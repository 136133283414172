import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AllTotaling = {
  __typename?: "AllTotaling";
  cashInBank?: Maybe<Scalars["Float"]>;
  cashInHand?: Maybe<Scalars["Float"]>;
  crTotal?: Maybe<Scalars["Float"]>;
  drTotal?: Maybe<Scalars["Float"]>;
  purchaseTotal?: Maybe<Scalars["Float"]>;
  saleTotal?: Maybe<Scalars["Float"]>;
  totalMatersSold?: Maybe<Scalars["Float"]>;
  totalStockAmount?: Maybe<Scalars["Float"]>;
};

export type AuthType = {
  __typename?: "AuthType";
  email: Scalars["String"];
  id: Scalars["Int"];
  jwtToken?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  timestamps: Timestamps;
};

export type BalancePagination = {
  __typename?: "BalancePagination";
  items?: Maybe<Array<BalanceType>>;
  total?: Maybe<Scalars["Int"]>;
};

export type CashBookType = {
  __typename?: "CashBookType";
  bfBalance?: Maybe<Scalars["Float"]>;
  payments?: Maybe<Array<ReceiptSlips>>;
  purchases?: Maybe<Array<SaleType>>;
  receipts?: Maybe<Array<ReceiptSlips>>;
  sales?: Maybe<Array<SaleType>>;
  vouchers?: Maybe<Array<VoucherType>>;
};

export type ChallanItem = {
  __typename?: "ChallanItem";
  meterOrSuits: Scalars["Float"];
  pcsOrBundle: Scalars["Float"];
  total: Scalars["Float"];
};

export type ChallanItemInput = {
  meterOrSuits: Scalars["Float"];
  pcsOrBundle: Scalars["Float"];
  total: Scalars["Float"];
};

export type CreateAccessTokenInput = {
  refreshToken: Scalars["String"];
};

export type CreateBalanceInput = {
  balance: Scalars["Float"];
  effectAmount?: InputMaybe<Scalars["Float"]>;
  evaluateAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CreatePartyInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  creditBalance?: InputMaybe<Scalars["Float"]>;
  debitBalance?: InputMaybe<Scalars["Float"]>;
  email?: InputMaybe<Scalars["String"]>;
  mobileNumber?: InputMaybe<Scalars["String"]>;
  openingBalance?: Scalars["Float"];
  partyCode: Scalars["String"];
  partyName: Scalars["String"];
  phoneNumber?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type CreateProductInput = {
  amount: Scalars["Float"];
  challanItem?: Array<ChallanItemInput>;
  itemCode: Scalars["String"];
  meterOrSuit: Scalars["Float"];
  name: Scalars["String"];
  pcsOrBundle: Scalars["Float"];
  quality?: InputMaybe<QualityEnums>;
  sale?: InputMaybe<Scalars["Int"]>;
  sumUpMeterOrSuitBalance?: InputMaybe<Scalars["Float"]>;
  sumUpPcsOrBundleBalance?: InputMaybe<Scalars["Float"]>;
  unitPurchasePrice: Scalars["Float"];
  userId: Scalars["Int"];
};

export type CreateReceiptSlipInput = {
  amount: Scalars["Float"];
  cashChequeBank?: InputMaybe<Scalars["String"]>;
  date: Scalars["DateTime"];
  onAccountOf: Scalars["String"];
  partyId: Scalars["Int"];
  signature: Scalars["String"];
  slipCode: Scalars["String"];
  slipType?: ReceiptSlipEnums;
  sumOfRupees: Scalars["String"];
  userId: Scalars["Int"];
};

export type CreateSaleInput = {
  addLess?: Scalars["Int"];
  address?: InputMaybe<Scalars["String"]>;
  anyPayment?: Scalars["Int"];
  city?: InputMaybe<Scalars["String"]>;
  date: Scalars["DateTime"];
  invoiceNumber?: InputMaybe<Scalars["String"]>;
  miscellaneous?: InputMaybe<Scalars["String"]>;
  partyId?: InputMaybe<Scalars["Int"]>;
  partyItem?: InputMaybe<EditPartyInput>;
  products?: InputMaybe<Array<CreateProductInput>>;
  returnSale?: Scalars["Boolean"];
  saleOnCash: Scalars["Boolean"];
  subTotal: Scalars["Float"];
  totalPrice: Scalars["Float"];
  type: SaleTypeEnums;
  userId?: Scalars["Int"];
};

export type CreateUserInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  password: Scalars["String"];
};

export type DeleteExpenseWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type DeletePartyWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type DeleteProductWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type DeleteSaleWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type DeleteUserWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type DeleteVoucherWithIds = {
  id?: Array<Scalars["Int"]>;
};

export type EditExpensesDetailsInput = {
  billNo?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  paid?: InputMaybe<Scalars["Float"]>;
  userId: Scalars["Float"];
};

export type EditPartyInput = {
  address?: InputMaybe<Scalars["String"]>;
  balance?: InputMaybe<Scalars["Float"]>;
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  creditBalance?: InputMaybe<Scalars["Float"]>;
  debitBalance?: InputMaybe<Scalars["Float"]>;
  email?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  mobileNumber?: InputMaybe<Scalars["String"]>;
  openingBalance?: InputMaybe<Scalars["Float"]>;
  partyCode?: InputMaybe<Scalars["String"]>;
  partyName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type EditProductInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  id: Scalars["Int"];
  itemCode?: InputMaybe<Scalars["String"]>;
  meterOrSuit?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  pcsOrBundle?: InputMaybe<Scalars["Float"]>;
  quality?: InputMaybe<QualityEnums>;
  unitPurchasePrice?: InputMaybe<Scalars["Float"]>;
};

export type EditSaleInput = {
  addLess?: Scalars["Int"];
  address?: InputMaybe<Scalars["String"]>;
  anyPayment?: Scalars["Int"];
  city?: InputMaybe<Scalars["String"]>;
  date: Scalars["DateTime"];
  id: Scalars["Int"];
  invoiceNumber?: InputMaybe<Scalars["String"]>;
  miscellaneous?: InputMaybe<Scalars["String"]>;
  partyId?: InputMaybe<Scalars["Int"]>;
  partyItem?: InputMaybe<EditPartyInput>;
  products?: InputMaybe<Array<CreateProductInput>>;
  returnSale?: Scalars["Boolean"];
  saleOnCash: Scalars["Boolean"];
  subTotal: Scalars["Float"];
  totalPrice: Scalars["Float"];
  type: SaleTypeEnums;
  userId?: Scalars["Int"];
};

export type EditUserInput = {
  id: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
};

export type EditVoucherCodeInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  code: Scalars["String"];
  id: Scalars["Int"];
  isScheduler?: InputMaybe<Scalars["Boolean"]>;
  outStandingAmount?: InputMaybe<Scalars["Float"]>;
  schedulerOf?: InputMaybe<SchedulerOf>;
  title: Scalars["String"];
  type: Scalars["String"];
  userId: Scalars["Float"];
};

export type EditVoucherDetailsInput = {
  date: Scalars["DateTime"];
  id: Scalars["Int"];
  remarks?: Scalars["String"];
  signature?: Scalars["String"];
  totalPaid: Scalars["Float"];
  totalReceived: Scalars["Float"];
  userId: Scalars["Int"];
  voucherItem?: InputMaybe<Array<InputVoucherDetailsInput>>;
  voucherNumber: Scalars["String"];
};

export type ExpenseReportType = {
  __typename?: "ExpenseReportType";
  amount: Scalars["Int"];
  expenseType: Scalars["String"];
  id: Scalars["Int"];
  reportDuration: Scalars["String"];
  timestamps: Timestamps;
  userId: Scalars["Int"];
};

export type ExpensesInput = {
  billNo: Scalars["String"];
  expenseDate: Scalars["DateTime"];
  expensesDetails?: InputMaybe<Array<ExpensesItemInput>>;
  total: Scalars["Int"];
  userId: Scalars["Float"];
};

export type ExpensesItemDetails = {
  __typename?: "ExpensesItemDetails";
  category: Scalars["String"];
  expense: ExpensesType;
  expenseCode: Scalars["String"];
  id: Scalars["Float"];
  paid: Scalars["Float"];
  timestamps: Timestamps;
};

export type ExpensesItemInput = {
  category: Scalars["String"];
  expenseCode: Scalars["String"];
  paid: Scalars["Float"];
};

export type ExpensesType = {
  __typename?: "ExpensesType";
  billNo: Scalars["String"];
  expenseDate: Scalars["DateTime"];
  expensesDetails?: Maybe<Array<ExpensesItemDetails>>;
  id: Scalars["Int"];
  timestamps: Timestamps;
  total: Scalars["Float"];
};

export type FindLatestSlip = {
  slipType: ReceiptSlipEnums;
};

export type FindPartyInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  crDr?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  mobileNumber?: InputMaybe<Scalars["String"]>;
  orderKey?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  partyCode?: InputMaybe<Scalars["String"]>;
  partyName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export type FindProductInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  challanItem?: InputMaybe<Array<ChallanItemInput>>;
  id?: InputMaybe<Scalars["Int"]>;
  itemCode?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  meterOrSuit?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pcsOrBundle?: InputMaybe<Scalars["Float"]>;
  quality?: InputMaybe<QualityEnums>;
  sale?: InputMaybe<FindSaleInput>;
  searchKey?: InputMaybe<Scalars["String"]>;
  searchKeyMax?: InputMaybe<Scalars["String"]>;
  searchKeyMin?: InputMaybe<Scalars["String"]>;
  unitPurchasePrice?: InputMaybe<Scalars["Float"]>;
};

export type FindReceiptSlip = {
  amount?: InputMaybe<Scalars["Float"]>;
  cashChequeBank?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  onAccountOf?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  partyId?: InputMaybe<Scalars["Int"]>;
  searchType?: InputMaybe<Scalars["String"]>;
  signature?: InputMaybe<Scalars["String"]>;
  slipCode?: InputMaybe<Scalars["String"]>;
  slipType: ReceiptSlipEnums;
  sortBy?: SortBy;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  sumOfRupees?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type FindSaleInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  party?: InputMaybe<Scalars["Int"]>;
  returnSale?: InputMaybe<Scalars["Boolean"]>;
  saleOnCash?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchType?: InputMaybe<Scalars["String"]>;
  sortBy?: SortBy;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  totalPrice?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<SaleTypeEnums>;
};

export type FindSlipById = {
  slipCode: Scalars["String"];
  slipType: ReceiptSlipEnums;
};

export type InitialBalanceAndCurrentBalance = {
  __typename?: "InitialBalanceAndCurrentBalance";
  currentBalance?: Maybe<BalanceType>;
  initialBalance?: Maybe<BalanceType>;
};

export type InputSchedulerCodeInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  code: Scalars["String"];
  isScheduler?: Scalars["Boolean"];
  outStandingAmount?: InputMaybe<Scalars["Float"]>;
  schedulerOf?: InputMaybe<SchedulerOf>;
  title: Scalars["String"];
  type: Scalars["String"];
  userId: Scalars["Float"];
};

export type InputSchedulerVoucherCodeList = {
  voucherCodes: Array<InputSchedulerCodeInput>;
};

export type InputVoucherCode = {
  amount?: InputMaybe<Scalars["Float"]>;
  code: Scalars["String"];
  isScheduler?: InputMaybe<Scalars["Boolean"]>;
  outStandingAmount?: InputMaybe<Scalars["Float"]>;
  schedulerOf?: InputMaybe<SchedulerOf>;
  title: Scalars["String"];
  type: Scalars["String"];
  userId: Scalars["Float"];
};

export type InputVoucherCodeList = {
  voucherCodes: Array<InputVoucherCode>;
};

export type InputVoucherDetailsInput = {
  description: Scalars["String"];
  paid?: InputMaybe<Scalars["Float"]>;
  recd?: InputMaybe<Scalars["Float"]>;
  voucherCode?: InputMaybe<Scalars["Int"]>;
  voucherItem?: InputMaybe<InputVoucherCode>;
};

export type MonthlyReportDbType = {
  __typename?: "MonthlyReportDbType";
  reportDuration?: Maybe<Scalars["String"]>;
  timestamps: Timestamps;
  totalMatersSold?: Maybe<Scalars["Float"]>;
  totalPayments?: Maybe<Scalars["Float"]>;
  totalPurcahses?: Maybe<Scalars["Float"]>;
  totalReceipts?: Maybe<Scalars["Float"]>;
  totalSales?: Maybe<Scalars["Float"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  DeleteExpenseWithIds?: Maybe<ExpensesType>;
  DeleteUserWithIds?: Maybe<User>;
  DeleteVoucherWithIds?: Maybe<VoucherType>;
  addBalance: BalanceType;
  createExpense: ExpensesType;
  createParty: PartyType;
  createProduct: Product;
  createSale: SaleType;
  createSchedulerVoucherCode: Array<VoucherCodeType>;
  createSlip: ReceiptSlips;
  createUser: User;
  createVoucher: VoucherType;
  createVoucherCode: Array<VoucherCodeType>;
  deletePartiesWithIds?: Maybe<PartyType>;
  deleteProductsWithIds?: Maybe<Product>;
  deleteReceipt?: Maybe<ReceiptSlips>;
  deleteSalesWithIds?: Maybe<SaleType>;
  deleteVoucherCode?: Maybe<VoucherCodeType>;
  editEditById: ExpensesType;
  editPartyById: PartyType;
  editProductById: Product;
  editSaleById: SaleType;
  editUserByIds: User;
  editVoucherInput: VoucherType;
  registerUser: AuthType;
  resetBalance: BalanceType;
  signInUser: AuthType;
  updateSlip: ReceiptSlips;
  updateVoucherCode?: Maybe<VoucherCodeType>;
};

export type MutationDeleteExpenseWithIdsArgs = {
  deleteExpenseWithIds: DeleteExpenseWithIds;
};

export type MutationDeleteUserWithIdsArgs = {
  DeleteUserWithIds: DeleteUserWithIds;
};

export type MutationDeleteVoucherWithIdsArgs = {
  deleteVoucherWithIds: DeleteVoucherWithIds;
};

export type MutationAddBalanceArgs = {
  createBalance: CreateBalanceInput;
};

export type MutationCreateExpenseArgs = {
  createExpenseInput: ExpensesInput;
};

export type MutationCreatePartyArgs = {
  createPartyInput: CreatePartyInput;
};

export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};

export type MutationCreateSaleArgs = {
  createSaleInput: CreateSaleInput;
};

export type MutationCreateSchedulerVoucherCodeArgs = {
  inputSchedulerVoucherCodeList: InputSchedulerVoucherCodeList;
};

export type MutationCreateSlipArgs = {
  createReceiptSlip: CreateReceiptSlipInput;
};

export type MutationCreateUserArgs = {
  CreateUserInput: CreateUserInput;
};

export type MutationCreateVoucherArgs = {
  createVoucherInput: VoucherInput;
};

export type MutationCreateVoucherCodeArgs = {
  inputVoucherCodeList: InputVoucherCodeList;
};

export type MutationDeletePartiesWithIdsArgs = {
  deletewithids: DeletePartyWithIds;
};

export type MutationDeleteProductsWithIdsArgs = {
  deletewithids: DeleteProductWithIds;
};

export type MutationDeleteReceiptArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteSalesWithIdsArgs = {
  deleteSaleWithIds: DeleteSaleWithIds;
};

export type MutationDeleteVoucherCodeArgs = {
  deleteVoucherWithIds: DeleteVoucherWithIds;
};

export type MutationEditEditByIdArgs = {
  editExpenseInput: EditExpensesDetailsInput;
};

export type MutationEditPartyByIdArgs = {
  editPartyInput: EditPartyInput;
};

export type MutationEditProductByIdArgs = {
  editProductInput: EditProductInput;
};

export type MutationEditSaleByIdArgs = {
  editSaleInput: EditSaleInput;
};

export type MutationEditUserByIdsArgs = {
  EditUserInput: EditUserInput;
};

export type MutationEditVoucherInputArgs = {
  editVoucherInput: EditVoucherDetailsInput;
};

export type MutationRegisterUserArgs = {
  RegisterUserInput: RegisterUserInput;
};

export type MutationResetBalanceArgs = {
  createBalance: CreateBalanceInput;
};

export type MutationSignInUserArgs = {
  SignInInput: SignInInput;
};

export type MutationUpdateSlipArgs = {
  updateReceiptInput: UpdateReceipt;
};

export type MutationUpdateVoucherCodeArgs = {
  updateVoucherCodeInput: EditVoucherCodeInput;
};

export type PaginationExpense = {
  __typename?: "PaginationExpense";
  items?: Maybe<Array<ExpensesType>>;
  total: Scalars["Float"];
};

export type PaginationForUser = {
  __typename?: "PaginationForUser";
  items?: Maybe<Array<User>>;
  total: Scalars["Float"];
};

export type PaginationParty = {
  __typename?: "PaginationParty";
  items?: Maybe<Array<PartyType>>;
  total: Scalars["Float"];
  totalBalance?: Maybe<Scalars["Float"]>;
};

export type PaginationPartyBalance = {
  __typename?: "PaginationPartyBalance";
  items?: Maybe<Array<PartyBalance>>;
  total: Scalars["Float"];
  totalBalance?: Maybe<Scalars["Float"]>;
};

export type PaginationProduct = {
  __typename?: "PaginationProduct";
  amount?: Maybe<Scalars["Float"]>;
  items?: Maybe<Array<Product>>;
  total: Scalars["Float"];
};

export type PaginationReceiptSlip = {
  __typename?: "PaginationReceiptSlip";
  items?: Maybe<Array<ReceiptSlips>>;
  total: Scalars["Float"];
  totalReciepts: Scalars["Float"];
};

export type PaginationSale = {
  __typename?: "PaginationSale";
  items?: Maybe<Array<SaleType>>;
  total: Scalars["Float"];
  totalSalePurchase: Scalars["Float"];
};

export type PaginationVoucher = {
  __typename?: "PaginationVoucher";
  items?: Maybe<Array<VoucherType>>;
  total: Scalars["Float"];
};

export type PartnersStat = {
  __typename?: "PartnersStat";
  name?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Float"]>;
};

export type PartyBalance = {
  __typename?: "PartyBalance";
  address?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["Float"]>;
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  creditBalance?: Maybe<Scalars["Float"]>;
  debitBalance?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  mobileNumber?: Maybe<Scalars["String"]>;
  openingBalance?: Maybe<Scalars["Float"]>;
  partyCode: Scalars["String"];
  partyName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  purchasedDiscountPrice: Scalars["Int"];
  sales?: Maybe<Array<SaleType>>;
  soldDiscountPrice: Scalars["Int"];
  timestamps: Timestamps;
};

export type PartyLedger = {
  __typename?: "PartyLedger";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  particularId?: Maybe<Scalars["Int"]>;
  particularType: SaleTypeEnums;
  partyId: Scalars["Int"];
  postBalance: Scalars["Float"];
  preBalance: Scalars["Float"];
  receiptSlip?: Maybe<ReceiptSlips>;
  sale?: Maybe<SaleType>;
  timestamps: Timestamps;
};

export type PartyType = {
  __typename?: "PartyType";
  address?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["Float"]>;
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  creditBalance?: Maybe<Scalars["Float"]>;
  debitBalance?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  mobileNumber?: Maybe<Scalars["String"]>;
  openingBalance?: Maybe<Scalars["Float"]>;
  partyCode: Scalars["String"];
  partyName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  sales?: Maybe<Array<SaleType>>;
  timestamps: Timestamps;
};

export type Product = {
  __typename?: "Product";
  amount: Scalars["Float"];
  challanItem?: Maybe<Array<ChallanItem>>;
  id: Scalars["Float"];
  itemCode: Scalars["String"];
  meterOrSuit: Scalars["Float"];
  name: Scalars["String"];
  pcsOrBundle: Scalars["Float"];
  quality?: Maybe<QualityEnums>;
  sale?: Maybe<SaleType>;
  sumUpMeterOrSuitBalance?: Maybe<Scalars["Float"]>;
  sumUpPcsOrBundleBalance?: Maybe<Scalars["Float"]>;
  timestamps: Timestamps;
  unitPurchasePrice: Scalars["Float"];
};

export type ProfitLossType = {
  __typename?: "ProfitLossType";
  balanceInBank?: Maybe<Scalars["Float"]>;
  cashInHand?: Maybe<Scalars["Float"]>;
  partner?: Maybe<Array<PartnersStat>>;
  partnersWithdrawal?: Maybe<Array<PartnersStat>>;
  payable?: Maybe<Scalars["Float"]>;
  receivable?: Maybe<Scalars["Float"]>;
  stockInShop?: Maybe<Scalars["Float"]>;
  totalExpenses?: Maybe<Scalars["Float"]>;
};

export type Query = {
  __typename?: "Query";
  CreateAccessTokenFromRefreshToken: AuthType;
  balanceSheet: PaginationPartyBalance;
  filterExpense: PaginationExpense;
  filterParties: PaginationParty;
  filterProducts: PaginationProduct;
  filterSale: PaginationSale;
  filterSlip: PaginationReceiptSlip;
  filterVoucher: PaginationVoucher;
  findSlipBySlipId: ReceiptSlips;
  findVoucherCodeByCode?: Maybe<VoucherType>;
  getAllUsers: PaginationForUser;
  getBalanceHistory: BalancePagination;
  getCashBook: CashBookType;
  getCurrentBalance: BalanceType;
  getExpenseWithId: ExpensesType;
  getHello: Scalars["String"];
  getInitialBalanceAndCurrentBalance: InitialBalanceAndCurrentBalance;
  getLatestExpense: ExpensesType;
  getLatestIdOfSlip: ReceiptSlips;
  getLatestParty: PartyType;
  getLatestProduct: Product;
  getLatestSale: SaleType;
  getLatestVoucher?: Maybe<VoucherType>;
  getLatestVoucherCode?: Maybe<VoucherCodeType>;
  getLedgerOfOneParty: Array<PartyLedger>;
  getMatchInvoice?: Maybe<SaleType>;
  getMonthlyExpenseReport: Array<ExpenseReportType>;
  getMonthlyReport: Array<MonthlyReportDbType>;
  getPartyById: PartyBalance;
  getPartyWithId: PartyType;
  getProductWithId: Product;
  getSaleWithId: SaleType;
  getStockList: Array<Product>;
  getTotalOfAll?: Maybe<AllTotaling>;
  getUserWithId: User;
  getVoucherCodeById: VoucherCodeType;
  getVoucherCodes: Array<VoucherCodeType>;
  getVoucherWithId: VoucherType;
  profitLossGetter: ProfitLossType;
  reformCashbook: Scalars["Boolean"];
  whoAmI: AuthType;
};

export type QueryCreateAccessTokenFromRefreshTokenArgs = {
  CreateAccessTokenInput: CreateAccessTokenInput;
};

export type QueryBalanceSheetArgs = {
  findBalance: FindPartyInput;
};

export type QueryFilterExpenseArgs = {
  getExpenseInput: GetExpenseInput;
};

export type QueryFilterPartiesArgs = {
  findMatchPartyInput: FindPartyInput;
};

export type QueryFilterProductsArgs = {
  findMatchProductInput: FindProductInput;
};

export type QueryFilterSaleArgs = {
  findMatchSaleInput: FindSaleInput;
};

export type QueryFilterSlipArgs = {
  findReceiptSlip: FindReceiptSlip;
};

export type QueryFilterVoucherArgs = {
  getVoucherInput: GetVoucherInput;
};

export type QueryFindSlipBySlipIdArgs = {
  findSlipById: FindSlipById;
};

export type QueryFindVoucherCodeByCodeArgs = {
  voucherCode: Scalars["String"];
};

export type QueryGetBalanceHistoryArgs = {
  page?: Scalars["Int"];
};

export type QueryGetCashBookArgs = {
  date?: InputMaybe<Scalars["DateTime"]>;
};

export type QueryGetExpenseWithIdArgs = {
  id: Scalars["Float"];
};

export type QueryGetLatestIdOfSlipArgs = {
  FindLatestSlip: FindLatestSlip;
};

export type QueryGetLatestSaleArgs = {
  type: SaleTypeEnums;
};

export type QueryGetLedgerOfOnePartyArgs = {
  id: Scalars["Float"];
};

export type QueryGetMatchInvoiceArgs = {
  invoiceNumber: Scalars["String"];
  type: SaleTypeEnums;
};

export type QueryGetPartyByIdArgs = {
  id: Scalars["Int"];
};

export type QueryGetPartyWithIdArgs = {
  id: Scalars["String"];
};

export type QueryGetProductWithIdArgs = {
  id: Scalars["String"];
};

export type QueryGetSaleWithIdArgs = {
  id: Scalars["Float"];
};

export type QueryGetStockListArgs = {
  itemCode: Scalars["String"];
};

export type QueryGetUserWithIdArgs = {
  id: Scalars["Float"];
};

export type QueryGetVoucherCodeByIdArgs = {
  id: Scalars["Float"];
};

export type QueryGetVoucherCodesArgs = {
  isScheduler?: Scalars["Boolean"];
  title?: Scalars["String"];
  type?: Scalars["String"];
};

export type QueryGetVoucherWithIdArgs = {
  id: Scalars["Float"];
};

export enum ReceiptSlipEnums {
  PaymentSlip = "paymentSlip",
  Receipt = "receipt",
}

export type ReceiptSlips = {
  __typename?: "ReceiptSlips";
  amount: Scalars["Float"];
  cashChequeBank: Scalars["String"];
  date: Scalars["DateTime"];
  id: Scalars["Int"];
  onAccountOf: Scalars["String"];
  partyDetail?: Maybe<PartyType>;
  partyId: Scalars["Int"];
  signature: Scalars["String"];
  slipCode?: Maybe<Scalars["String"]>;
  slipType: ReceiptSlipEnums;
  sumOfRupees?: Maybe<Scalars["String"]>;
  timestamps: Timestamps;
  userId: Scalars["Int"];
};

export type RegisterUserInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  password: Scalars["String"];
};

export enum SchedulerOf {
  Daily = "daily",
  Monthly = "monthly",
  None = "none",
  Weekly = "weekly",
}

export type SignInInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export enum SortBy {
  Date = "date",
  Id = "id",
  PartyName = "partyName",
  RecieptPartyName = "recieptPartyName",
}

export type Timestamps = {
  __typename?: "Timestamps";
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["String"];
};

export type UpdateReceipt = {
  amount: Scalars["Float"];
  cashChequeBank?: InputMaybe<Scalars["String"]>;
  date: Scalars["DateTime"];
  id: Scalars["Int"];
  onAccountOf: Scalars["String"];
  partyId: Scalars["Int"];
  signature: Scalars["String"];
  slipCode: Scalars["String"];
  slipType?: ReceiptSlipEnums;
  sumOfRupees: Scalars["String"];
  userId: Scalars["Int"];
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  password: Scalars["String"];
};

export type VoucherCodeType = {
  __typename?: "VoucherCodeType";
  amount?: Maybe<Scalars["Float"]>;
  code: Scalars["String"];
  id: Scalars["Int"];
  isScheduler?: Maybe<Scalars["Boolean"]>;
  outStandingAmount?: Maybe<Scalars["Float"]>;
  schedulerOf?: Maybe<SchedulerOf>;
  timestamps: Timestamps;
  title: Scalars["String"];
  type: Scalars["String"];
  userId: Scalars["Float"];
  voucherItem?: Maybe<Array<VoucherItemType>>;
};

export type VoucherInput = {
  date: Scalars["DateTime"];
  remarks?: Scalars["String"];
  signature?: Scalars["String"];
  totalPaid: Scalars["Float"];
  totalReceived: Scalars["Float"];
  userId: Scalars["Int"];
  voucherItem?: InputMaybe<Array<InputVoucherDetailsInput>>;
  voucherNumber: Scalars["String"];
};

export type VoucherItemType = {
  __typename?: "VoucherItemType";
  description: Scalars["String"];
  effectChainAmount?: Maybe<Scalars["Float"]>;
  id: Scalars["Float"];
  paid?: Maybe<Scalars["Float"]>;
  recd?: Maybe<Scalars["Float"]>;
  voucher?: Maybe<VoucherType>;
  voucherCode?: Maybe<Scalars["Float"]>;
  voucherCodeDetail?: Maybe<VoucherCodeType>;
};

export type VoucherType = {
  __typename?: "VoucherType";
  date?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  remarks: Scalars["String"];
  signature: Scalars["String"];
  timestamps: Timestamps;
  totalPaid: Scalars["Float"];
  totalReceived: Scalars["Float"];
  voucherItem?: Maybe<Array<VoucherItemType>>;
  voucherNumber: Scalars["String"];
};

export type BalanceType = {
  __typename?: "balanceType";
  balance?: Maybe<Scalars["Float"]>;
  effectAmount?: Maybe<Scalars["Float"]>;
  evaluateAt?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
  preBalance?: Maybe<Scalars["Float"]>;
  userId: Scalars["Int"];
};

export type GetExpenseInput = {
  billNo?: InputMaybe<Scalars["Float"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export type GetVoucherInput = {
  endDate?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  voucherNumber?: InputMaybe<Scalars["Int"]>;
};

export enum QualityEnums {
  High = "high",
  Low = "low",
  Medium = "medium",
}

export type SaleType = {
  __typename?: "saleType";
  addLess: Scalars["Int"];
  address: Scalars["String"];
  anyPayment: Scalars["Int"];
  city: Scalars["String"];
  date: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  invoiceNumber?: Maybe<Scalars["String"]>;
  miscellaneous?: Maybe<Scalars["String"]>;
  party?: Maybe<PartyType>;
  products?: Maybe<Array<Product>>;
  returnSale: Scalars["Boolean"];
  saleOnCash: Scalars["Boolean"];
  subTotal: Scalars["Float"];
  timestamps: Timestamps;
  totalPrice: Scalars["Float"];
  type: SaleTypeEnums;
};

export enum SaleTypeEnums {
  PaymentSlip = "paymentSlip",
  Purchase = "purchase",
  Receipt = "receipt",
  Sale = "sale",
}

export type SignInUserMutationVariables = Exact<{
  SignInInput: SignInInput;
}>;

export type SignInUserMutation = {
  __typename?: "Mutation";
  signInUser: {
    __typename?: "AuthType";
    id: number;
    name: string;
    email: string;
    password: string;
    jwtToken?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type RegisterUserMutationVariables = Exact<{
  RegisterUserInput: RegisterUserInput;
}>;

export type RegisterUserMutation = {
  __typename?: "Mutation";
  registerUser: {
    __typename?: "AuthType";
    id: number;
    name: string;
    email: string;
    jwtToken?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type UpdateSlipMutationVariables = Exact<{
  updateReceiptInput: UpdateReceipt;
}>;

export type UpdateSlipMutation = {
  __typename?: "Mutation";
  updateSlip: {
    __typename?: "ReceiptSlips";
    id: number;
    partyId: number;
    date: any;
    amount: number;
    slipType: ReceiptSlipEnums;
    cashChequeBank: string;
    signature: string;
    sumOfRupees?: string | null;
    partyDetail?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      balance?: number | null;
      email?: string | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type AddBalanceMutationVariables = Exact<{
  createBalance: CreateBalanceInput;
}>;

export type AddBalanceMutation = {
  __typename?: "Mutation";
  addBalance: {
    __typename?: "balanceType";
    id: number;
    balance?: number | null;
    preBalance?: number | null;
    userId: number;
  };
};

export type CreateExpenseMutationVariables = Exact<{
  createExpenseInput: ExpensesInput;
}>;

export type CreateExpenseMutation = {
  __typename?: "Mutation";
  createExpense: {
    __typename?: "ExpensesType";
    id: number;
    billNo: string;
    total: number;
    expenseDate: any;
    expensesDetails?: Array<{
      __typename?: "ExpensesItemDetails";
      id: number;
      category: string;
      expenseCode: string;
      paid: number;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type CreatePartyMutationVariables = Exact<{
  createPartyInput: CreatePartyInput;
}>;

export type CreatePartyMutation = {
  __typename?: "Mutation";
  createParty: {
    __typename?: "PartyType";
    id: number;
    partyCode: string;
    partyName: string;
    company?: string | null;
    phoneNumber?: string | null;
    mobileNumber?: string | null;
    address?: string | null;
    email?: string | null;
    balance?: number | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type CreateProductMutationVariables = Exact<{
  createProductInput: CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: "Mutation";
  createProduct: {
    __typename?: "Product";
    id: number;
    itemCode: string;
    quality?: QualityEnums | null;
    name: string;
    unitPurchasePrice: number;
    amount: number;
    pcsOrBundle: number;
    meterOrSuit: number;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type CreateSaleMutationVariables = Exact<{
  createSaleInput: CreateSaleInput;
}>;

export type CreateSaleMutation = {
  __typename?: "Mutation";
  createSale: {
    __typename?: "saleType";
    id: number;
    party?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      sales?: Array<{
        __typename?: "saleType";
        id: number;
        totalPrice: number;
        subTotal: number;
        address: string;
        city: string;
        type: SaleTypeEnums;
        saleOnCash: boolean;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      }> | null;
    } | null;
  };
};

export type CreateSchedulerVoucherCodeMutationVariables = Exact<{
  inputSchedulerVoucherCodeList: InputSchedulerVoucherCodeList;
}>;

export type CreateSchedulerVoucherCodeMutation = {
  __typename?: "Mutation";
  createSchedulerVoucherCode: Array<{
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    isScheduler?: boolean | null;
    amount?: number | null;
    outStandingAmount?: number | null;
    schedulerOf?: SchedulerOf | null;
    userId: number;
  }>;
};

export type CreateSlipMutationVariables = Exact<{
  createReceiptSlip: CreateReceiptSlipInput;
}>;

export type CreateSlipMutation = {
  __typename?: "Mutation";
  createSlip: {
    __typename?: "ReceiptSlips";
    id: number;
    partyId: number;
    date: any;
    amount: number;
    slipType: ReceiptSlipEnums;
    cashChequeBank: string;
    signature: string;
    sumOfRupees?: string | null;
    partyDetail?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      balance?: number | null;
      email?: string | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type CreateVoucherMutationVariables = Exact<{
  createVoucherInput: VoucherInput;
}>;

export type CreateVoucherMutation = {
  __typename?: "Mutation";
  createVoucher: {
    __typename?: "VoucherType";
    id: number;
    totalPaid: number;
    totalReceived: number;
    date?: string | null;
    voucherItem?: Array<{
      __typename?: "VoucherItemType";
      id: number;
      recd?: number | null;
      paid?: number | null;
      voucherCodeDetail?: {
        __typename?: "VoucherCodeType";
        id: number;
        title: string;
        code: string;
        userId: number;
      } | null;
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type CreateVoucherCodeMutationVariables = Exact<{
  inputVoucherCodeList: InputVoucherCodeList;
}>;

export type CreateVoucherCodeMutation = {
  __typename?: "Mutation";
  createVoucherCode: Array<{
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    userId: number;
  }>;
};

export type DeleteExpenseWithIdsMutationVariables = Exact<{
  deleteExpenseWithIds: DeleteExpenseWithIds;
}>;

export type DeleteExpenseWithIdsMutation = {
  __typename?: "Mutation";
  DeleteExpenseWithIds?: {
    __typename?: "ExpensesType";
    id: number;
    billNo: string;
    total: number;
    expenseDate: any;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type DeleteSalesWithIdsMutationVariables = Exact<{
  deleteSaleWithIds: DeleteSaleWithIds;
}>;

export type DeleteSalesWithIdsMutation = {
  __typename?: "Mutation";
  deleteSalesWithIds?: {
    __typename?: "saleType";
    id: number;
    totalPrice: number;
    addLess: number;
    anyPayment: number;
    subTotal: number;
    returnSale: boolean;
    address: string;
    city: string;
    saleOnCash: boolean;
    miscellaneous?: string | null;
    date: any;
    invoiceNumber?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type DeletePartiesWithIdsMutationVariables = Exact<{
  deletewithids: DeletePartyWithIds;
}>;

export type DeletePartiesWithIdsMutation = {
  __typename?: "Mutation";
  deletePartiesWithIds?: {
    __typename?: "PartyType";
    id: number;
    partyCode: string;
    partyName: string;
    company?: string | null;
    phoneNumber?: string | null;
    city?: string | null;
    mobileNumber?: string | null;
    address?: string | null;
    email?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type DeleteProductsWithIdsMutationVariables = Exact<{
  deletewithids: DeleteProductWithIds;
}>;

export type DeleteProductsWithIdsMutation = {
  __typename?: "Mutation";
  deleteProductsWithIds?: {
    __typename?: "Product";
    id: number;
    itemCode: string;
    quality?: QualityEnums | null;
    name: string;
    unitPurchasePrice: number;
    amount: number;
    pcsOrBundle: number;
    meterOrSuit: number;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type DeleteReceiptMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteReceiptMutation = {
  __typename?: "Mutation";
  deleteReceipt?: {
    __typename?: "ReceiptSlips";
    id: number;
    partyId: number;
    date: any;
    amount: number;
    slipType: ReceiptSlipEnums;
    cashChequeBank: string;
    signature: string;
    sumOfRupees?: string | null;
    partyDetail?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      balance?: number | null;
      email?: string | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type DeleteVoucherWithIdsMutationVariables = Exact<{
  deleteVoucherWithIds: DeleteVoucherWithIds;
}>;

export type DeleteVoucherWithIdsMutation = {
  __typename?: "Mutation";
  DeleteVoucherWithIds?: { __typename?: "VoucherType"; id: number } | null;
};

export type DeleteVoucherCodeMutationVariables = Exact<{
  deleteVoucherWithIds: DeleteVoucherWithIds;
}>;

export type DeleteVoucherCodeMutation = {
  __typename?: "Mutation";
  deleteVoucherCode?: {
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    userId: number;
  } | null;
};

export type EditProductByIdMutationVariables = Exact<{
  editProductInput: EditProductInput;
}>;

export type EditProductByIdMutation = {
  __typename?: "Mutation";
  editProductById: {
    __typename?: "Product";
    id: number;
    itemCode: string;
    quality?: QualityEnums | null;
    name: string;
    unitPurchasePrice: number;
    amount: number;
    pcsOrBundle: number;
    meterOrSuit: number;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type EditPartyByIdMutationVariables = Exact<{
  editPartyInput: EditPartyInput;
}>;

export type EditPartyByIdMutation = {
  __typename?: "Mutation";
  editPartyById: {
    __typename?: "PartyType";
    id: number;
    partyCode: string;
    partyName: string;
    company?: string | null;
    phoneNumber?: string | null;
    city?: string | null;
    mobileNumber?: string | null;
    address?: string | null;
    balance?: number | null;
    email?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type EditSaleByIdMutationVariables = Exact<{
  editSaleInput: EditSaleInput;
}>;

export type EditSaleByIdMutation = {
  __typename?: "Mutation";
  editSaleById: {
    __typename?: "saleType";
    id: number;
    type: SaleTypeEnums;
    totalPrice: number;
    addLess: number;
    anyPayment: number;
    subTotal: number;
    returnSale: boolean;
    address: string;
    city: string;
    saleOnCash: boolean;
    miscellaneous?: string | null;
    date: any;
    invoiceNumber?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type EditVoucherInputMutationVariables = Exact<{
  editVoucherInput: EditVoucherDetailsInput;
}>;

export type EditVoucherInputMutation = {
  __typename?: "Mutation";
  editVoucherInput: {
    __typename?: "VoucherType";
    id: number;
    totalPaid: number;
    totalReceived: number;
    remarks: string;
    signature: string;
    voucherNumber: string;
    date?: string | null;
    voucherItem?: Array<{
      __typename?: "VoucherItemType";
      id: number;
      description: string;
      recd?: number | null;
      paid?: number | null;
      voucherCodeDetail?: {
        __typename?: "VoucherCodeType";
        id: number;
        title: string;
        code: string;
        type: string;
        userId: number;
      } | null;
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type UpdateVoucherCodeMutationVariables = Exact<{
  updateVoucherCodeInput: EditVoucherCodeInput;
}>;

export type UpdateVoucherCodeMutation = {
  __typename?: "Mutation";
  updateVoucherCode?: {
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    userId: number;
  } | null;
};

export type WhoAmIQueryVariables = Exact<{ [key: string]: never }>;

export type WhoAmIQuery = {
  __typename?: "Query";
  whoAmI: {
    __typename?: "AuthType";
    id: number;
    name: string;
    email: string;
    password: string;
    jwtToken?: string | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type FindVoucherCodeByCodeQueryVariables = Exact<{
  voucherCode: Scalars["String"];
}>;

export type FindVoucherCodeByCodeQuery = {
  __typename?: "Query";
  findVoucherCodeByCode?: {
    __typename?: "VoucherType";
    id: number;
    totalPaid: number;
    totalReceived: number;
    remarks: string;
    signature: string;
    voucherNumber: string;
    date?: string | null;
    voucherItem?: Array<{
      __typename?: "VoucherItemType";
      id: number;
      description: string;
      recd?: number | null;
      paid?: number | null;
      voucherCodeDetail?: {
        __typename?: "VoucherCodeType";
        id: number;
        title: string;
        code: string;
        type: string;
        userId: number;
      } | null;
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type GetVoucherCodeByIdQueryVariables = Exact<{
  id: Scalars["Float"];
}>;

export type GetVoucherCodeByIdQuery = {
  __typename?: "Query";
  getVoucherCodeById: {
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    isScheduler?: boolean | null;
    amount?: number | null;
    outStandingAmount?: number | null;
    schedulerOf?: SchedulerOf | null;
    userId: number;
    voucherItem?: Array<{
      __typename?: "VoucherItemType";
      id: number;
      voucherCode?: number | null;
      description: string;
      recd?: number | null;
      paid?: number | null;
      effectChainAmount?: number | null;
      voucher?: {
        __typename?: "VoucherType";
        id: number;
        voucherNumber: string;
        date?: string | null;
      } | null;
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type GetBalanceHistoryQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type GetBalanceHistoryQuery = {
  __typename?: "Query";
  getBalanceHistory: {
    __typename?: "BalancePagination";
    total?: number | null;
    items?: Array<{
      __typename?: "balanceType";
      id: number;
      balance?: number | null;
      preBalance?: number | null;
      userId: number;
      evaluateAt?: string | null;
      effectAmount?: number | null;
    }> | null;
  };
};

export type GetCashBookQueryVariables = Exact<{
  date?: InputMaybe<Scalars["DateTime"]>;
}>;

export type GetCashBookQuery = {
  __typename?: "Query";
  getCashBook: {
    __typename?: "CashBookType";
    bfBalance?: number | null;
    sales?: Array<{
      __typename?: "saleType";
      id: number;
      type: SaleTypeEnums;
      totalPrice: number;
      date: any;
      saleOnCash: boolean;
      invoiceNumber?: string | null;
      party?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        company?: string | null;
        city?: string | null;
        phoneNumber?: string | null;
        address?: string | null;
        email?: string | null;
      } | null;
    }> | null;
    purchases?: Array<{
      __typename?: "saleType";
      id: number;
      type: SaleTypeEnums;
      totalPrice: number;
      date: any;
      saleOnCash: boolean;
      invoiceNumber?: string | null;
      party?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        company?: string | null;
        city?: string | null;
        phoneNumber?: string | null;
        address?: string | null;
        email?: string | null;
      } | null;
    }> | null;
    receipts?: Array<{
      __typename?: "ReceiptSlips";
      id: number;
      partyId: number;
      userId: number;
      slipCode?: string | null;
      date: any;
      onAccountOf: string;
      slipType: ReceiptSlipEnums;
      cashChequeBank: string;
      signature: string;
      sumOfRupees?: string | null;
      amount: number;
      partyDetail?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        company?: string | null;
        city?: string | null;
        phoneNumber?: string | null;
        address?: string | null;
        email?: string | null;
      } | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
    payments?: Array<{
      __typename?: "ReceiptSlips";
      id: number;
      partyId: number;
      userId: number;
      slipCode?: string | null;
      date: any;
      onAccountOf: string;
      slipType: ReceiptSlipEnums;
      cashChequeBank: string;
      signature: string;
      sumOfRupees?: string | null;
      amount: number;
      partyDetail?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        city?: string | null;
        company?: string | null;
        phoneNumber?: string | null;
        address?: string | null;
        email?: string | null;
      } | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
    vouchers?: Array<{
      __typename?: "VoucherType";
      id: number;
      totalPaid: number;
      totalReceived: number;
      remarks: string;
      signature: string;
      voucherNumber: string;
      date?: string | null;
      voucherItem?: Array<{
        __typename?: "VoucherItemType";
        id: number;
        description: string;
        recd?: number | null;
        paid?: number | null;
        voucherCodeDetail?: {
          __typename?: "VoucherCodeType";
          id: number;
          title: string;
          code: string;
          type: string;
          userId: number;
        } | null;
      }> | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type GetCurrentBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentBalanceQuery = {
  __typename?: "Query";
  getCurrentBalance: {
    __typename?: "balanceType";
    id: number;
    balance?: number | null;
    preBalance?: number | null;
    userId: number;
  };
};

export type GetInitialBalanceAndCurrentBalanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInitialBalanceAndCurrentBalanceQuery = {
  __typename?: "Query";
  getInitialBalanceAndCurrentBalance: {
    __typename?: "InitialBalanceAndCurrentBalance";
    currentBalance?: {
      __typename?: "balanceType";
      id: number;
      balance?: number | null;
      preBalance?: number | null;
      userId: number;
    } | null;
    initialBalance?: {
      __typename?: "balanceType";
      id: number;
      balance?: number | null;
      preBalance?: number | null;
      userId: number;
    } | null;
  };
};

export type FilterExpenseQueryVariables = Exact<{
  getExpenseInput: GetExpenseInput;
}>;

export type FilterExpenseQuery = {
  __typename?: "Query";
  filterExpense: {
    __typename?: "PaginationExpense";
    total: number;
    items?: Array<{
      __typename?: "ExpensesType";
      id: number;
      billNo: string;
      total: number;
      expenseDate: any;
      expensesDetails?: Array<{
        __typename?: "ExpensesItemDetails";
        id: number;
        category: string;
        expenseCode: string;
        paid: number;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      }> | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type GetExpenseWithIdQueryVariables = Exact<{
  id: Scalars["Float"];
}>;

export type GetExpenseWithIdQuery = {
  __typename?: "Query";
  getExpenseWithId: {
    __typename?: "ExpensesType";
    id: number;
    billNo: string;
    total: number;
    expenseDate: any;
    expensesDetails?: Array<{
      __typename?: "ExpensesItemDetails";
      id: number;
      category: string;
      expenseCode: string;
      paid: number;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type GetLatestExpenseQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestExpenseQuery = {
  __typename?: "Query";
  getLatestExpense: { __typename?: "ExpensesType"; id: number; billNo: string };
};

export type GetLatestPartyQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestPartyQuery = {
  __typename?: "Query";
  getLatestParty: { __typename?: "PartyType"; id: number; partyCode: string };
};

export type GetLatestProductQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestProductQuery = {
  __typename?: "Query";
  getLatestProduct: { __typename?: "Product"; id: number; itemCode: string };
};

export type GetLatestSaleQueryVariables = Exact<{
  type: SaleTypeEnums;
}>;

export type GetLatestSaleQuery = {
  __typename?: "Query";
  getLatestSale: {
    __typename?: "saleType";
    id: number;
    type: SaleTypeEnums;
    totalPrice: number;
    addLess: number;
    anyPayment: number;
    subTotal: number;
    address: string;
    city: string;
    saleOnCash: boolean;
    miscellaneous?: string | null;
    date: any;
    invoiceNumber?: string | null;
  };
};

export type GetLatestIdOfSlipQueryVariables = Exact<{
  FindLatestSlip: FindLatestSlip;
}>;

export type GetLatestIdOfSlipQuery = {
  __typename?: "Query";
  getLatestIdOfSlip: {
    __typename?: "ReceiptSlips";
    id: number;
    slipCode?: string | null;
    date: any;
  };
};

export type GetLatestVoucherQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestVoucherQuery = {
  __typename?: "Query";
  getLatestVoucher?: {
    __typename?: "VoucherType";
    id: number;
    voucherNumber: string;
    date?: string | null;
  } | null;
};

export type GetLatestVoucherCodeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestVoucherCodeQuery = {
  __typename?: "Query";
  getLatestVoucherCode?: {
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    isScheduler?: boolean | null;
    amount?: number | null;
    outStandingAmount?: number | null;
    schedulerOf?: SchedulerOf | null;
    userId: number;
  } | null;
};

export type GetLedgerOfOnePartyQueryVariables = Exact<{
  id: Scalars["Float"];
}>;

export type GetLedgerOfOnePartyQuery = {
  __typename?: "Query";
  getLedgerOfOneParty: Array<{
    __typename?: "PartyLedger";
    partyId: number;
    id: number;
    preBalance: number;
    postBalance: number;
    particularId?: number | null;
    particularType: SaleTypeEnums;
    description?: string | null;
    sale?: {
      __typename?: "saleType";
      id: number;
      type: SaleTypeEnums;
      description?: string | null;
      totalPrice: number;
      addLess: number;
      anyPayment: number;
      subTotal: number;
      address: string;
      returnSale: boolean;
      city: string;
      saleOnCash: boolean;
      miscellaneous?: string | null;
      date: any;
      invoiceNumber?: string | null;
    } | null;
    receiptSlip?: {
      __typename?: "ReceiptSlips";
      id: number;
      onAccountOf: string;
      partyId: number;
      date: any;
      slipType: ReceiptSlipEnums;
      cashChequeBank: string;
      signature: string;
      slipCode?: string | null;
      amount: number;
      sumOfRupees?: string | null;
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  }>;
};

export type GetMonthlyExpenseReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMonthlyExpenseReportQuery = {
  __typename?: "Query";
  getMonthlyExpenseReport: Array<{
    __typename?: "ExpenseReportType";
    reportDuration: string;
    expenseType: string;
    userId: number;
    amount: number;
    id: number;
  }>;
};

export type GetMonthlyReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetMonthlyReportQuery = {
  __typename?: "Query";
  getMonthlyReport: Array<{
    __typename?: "MonthlyReportDbType";
    totalSales?: number | null;
    totalPurcahses?: number | null;
    totalPayments?: number | null;
    totalReceipts?: number | null;
    totalMatersSold?: number | null;
    reportDuration?: string | null;
    userId?: number | null;
  }>;
};

export type FilterPartiesQueryVariables = Exact<{
  findMatchPartyInput: FindPartyInput;
}>;

export type FilterPartiesQuery = {
  __typename?: "Query";
  filterParties: {
    __typename?: "PaginationParty";
    total: number;
    totalBalance?: number | null;
    items?: Array<{
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      email?: string | null;
      openingBalance?: number | null;
      city?: string | null;
      balance?: number | null;
      debitBalance?: number | null;
      creditBalance?: number | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type GetPartyByIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetPartyByIdQuery = {
  __typename?: "Query";
  getPartyById: {
    __typename?: "PartyBalance";
    id: number;
    partyCode: string;
    partyName: string;
    company?: string | null;
    phoneNumber?: string | null;
    mobileNumber?: string | null;
    address?: string | null;
    openingBalance?: number | null;
    email?: string | null;
    city?: string | null;
    balance?: number | null;
    creditBalance?: number | null;
    debitBalance?: number | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type GetPartyWithIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetPartyWithIdQuery = {
  __typename?: "Query";
  getPartyWithId: {
    __typename?: "PartyType";
    id: number;
    partyCode: string;
    partyName: string;
    company?: string | null;
    phoneNumber?: string | null;
    mobileNumber?: string | null;
    address?: string | null;
    openingBalance?: number | null;
    email?: string | null;
    city?: string | null;
    balance?: number | null;
    creditBalance?: number | null;
    debitBalance?: number | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type BalanceSheetQueryVariables = Exact<{
  findMatchPartyInput: FindPartyInput;
}>;

export type BalanceSheetQuery = {
  __typename?: "Query";
  balanceSheet: {
    __typename?: "PaginationPartyBalance";
    total: number;
    totalBalance?: number | null;
    items?: Array<{
      __typename?: "PartyBalance";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      email?: string | null;
      city?: string | null;
      balance?: number | null;
      debitBalance?: number | null;
      creditBalance?: number | null;
      openingBalance?: number | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type GetProductWithIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetProductWithIdQuery = {
  __typename?: "Query";
  getProductWithId: {
    __typename?: "Product";
    id: number;
    itemCode: string;
    quality?: QualityEnums | null;
    name: string;
    unitPurchasePrice: number;
    amount: number;
    pcsOrBundle: number;
    meterOrSuit: number;
    sumUpPcsOrBundleBalance?: number | null;
    sumUpMeterOrSuitBalance?: number | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type GetStockListQueryVariables = Exact<{
  itemCode: Scalars["String"];
}>;

export type GetStockListQuery = {
  __typename?: "Query";
  getStockList: Array<{
    __typename?: "Product";
    id: number;
    itemCode: string;
    quality?: QualityEnums | null;
    name: string;
    unitPurchasePrice: number;
    amount: number;
    pcsOrBundle: number;
    sumUpMeterOrSuitBalance?: number | null;
    sumUpPcsOrBundleBalance?: number | null;
    meterOrSuit: number;
    sale?: {
      __typename?: "saleType";
      id: number;
      type: SaleTypeEnums;
      totalPrice: number;
      addLess: number;
      anyPayment: number;
      subTotal: number;
      address: string;
      city: string;
      saleOnCash: boolean;
      miscellaneous?: string | null;
      returnSale: boolean;
      date: any;
      invoiceNumber?: string | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  }>;
};

export type FilterProductsQueryVariables = Exact<{
  findMatchProductInput: FindProductInput;
}>;

export type FilterProductsQuery = {
  __typename?: "Query";
  filterProducts: {
    __typename?: "PaginationProduct";
    total: number;
    amount?: number | null;
    items?: Array<{
      __typename?: "Product";
      id: number;
      itemCode: string;
      quality?: QualityEnums | null;
      name: string;
      unitPurchasePrice: number;
      amount: number;
      sumUpMeterOrSuitBalance?: number | null;
      sumUpPcsOrBundleBalance?: number | null;
      pcsOrBundle: number;
      meterOrSuit: number;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type FilterSaleQueryVariables = Exact<{
  findMatchSaleInput: FindSaleInput;
}>;

export type FilterSaleQuery = {
  __typename?: "Query";
  filterSale: {
    __typename?: "PaginationSale";
    totalSalePurchase: number;
    total: number;
    items?: Array<{
      __typename?: "saleType";
      id: number;
      totalPrice: number;
      subTotal: number;
      returnSale: boolean;
      address: string;
      type: SaleTypeEnums;
      addLess: number;
      invoiceNumber?: string | null;
      date: any;
      miscellaneous?: string | null;
      anyPayment: number;
      city: string;
      saleOnCash: boolean;
      party?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        company?: string | null;
        phoneNumber?: string | null;
        city?: string | null;
        mobileNumber?: string | null;
        address?: string | null;
        openingBalance?: number | null;
        email?: string | null;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      } | null;
      products?: Array<{
        __typename?: "Product";
        id: number;
        itemCode: string;
        quality?: QualityEnums | null;
        name: string;
        unitPurchasePrice: number;
        pcsOrBundle: number;
        meterOrSuit: number;
        amount: number;
        challanItem?: Array<{
          __typename?: "ChallanItem";
          total: number;
          pcsOrBundle: number;
          meterOrSuits: number;
        }> | null;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      }> | null;
    }> | null;
  };
};

export type FilterSlipQueryVariables = Exact<{
  findReceiptSlip: FindReceiptSlip;
}>;

export type FilterSlipQuery = {
  __typename?: "Query";
  filterSlip: {
    __typename?: "PaginationReceiptSlip";
    total: number;
    totalReciepts: number;
    items?: Array<{
      __typename?: "ReceiptSlips";
      id: number;
      onAccountOf: string;
      partyId: number;
      date: any;
      slipType: ReceiptSlipEnums;
      cashChequeBank: string;
      signature: string;
      slipCode?: string | null;
      amount: number;
      sumOfRupees?: string | null;
      partyDetail?: {
        __typename?: "PartyType";
        id: number;
        partyCode: string;
        partyName: string;
        company?: string | null;
        phoneNumber?: string | null;
        city?: string | null;
        mobileNumber?: string | null;
        address?: string | null;
        balance?: number | null;
        creditBalance?: number | null;
        debitBalance?: number | null;
        email?: string | null;
      } | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type FindSlipBySlipIdQueryVariables = Exact<{
  findSlipById: FindSlipById;
}>;

export type FindSlipBySlipIdQuery = {
  __typename?: "Query";
  findSlipBySlipId: {
    __typename?: "ReceiptSlips";
    id: number;
    onAccountOf: string;
    partyId: number;
    date: any;
    slipType: ReceiptSlipEnums;
    cashChequeBank: string;
    signature: string;
    slipCode?: string | null;
    amount: number;
    sumOfRupees?: string | null;
    partyDetail?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      balance?: number | null;
      creditBalance?: number | null;
      debitBalance?: number | null;
      email?: string | null;
    } | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  };
};

export type GetTotalOfAllQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalOfAllQuery = {
  __typename?: "Query";
  getTotalOfAll?: {
    __typename?: "AllTotaling";
    crTotal?: number | null;
    drTotal?: number | null;
    purchaseTotal?: number | null;
    saleTotal?: number | null;
    totalMatersSold?: number | null;
    cashInBank?: number | null;
    cashInHand?: number | null;
    totalStockAmount?: number | null;
  } | null;
};

export type FilterVoucherQueryVariables = Exact<{
  getVoucherInput: GetVoucherInput;
}>;

export type FilterVoucherQuery = {
  __typename?: "Query";
  filterVoucher: {
    __typename?: "PaginationVoucher";
    total: number;
    items?: Array<{
      __typename?: "VoucherType";
      id: number;
      totalPaid: number;
      remarks: string;
      totalReceived: number;
      signature: string;
      voucherNumber: string;
      date?: string | null;
      voucherItem?: Array<{
        __typename?: "VoucherItemType";
        id: number;
        description: string;
        recd?: number | null;
        paid?: number | null;
        voucherCodeDetail?: {
          __typename?: "VoucherCodeType";
          id: number;
          title: string;
          code: string;
          userId: number;
        } | null;
      }> | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
  };
};

export type GetVoucherCodesQueryVariables = Exact<{
  isScheduler?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}>;

export type GetVoucherCodesQuery = {
  __typename?: "Query";
  getVoucherCodes: Array<{
    __typename?: "VoucherCodeType";
    id: number;
    title: string;
    code: string;
    type: string;
    isScheduler?: boolean | null;
    amount?: number | null;
    outStandingAmount?: number | null;
    schedulerOf?: SchedulerOf | null;
    userId: number;
    voucherItem?: Array<{
      __typename?: "VoucherItemType";
      recd?: number | null;
      paid?: number | null;
      voucher?: {
        __typename?: "VoucherType";
        voucherNumber: string;
        date?: string | null;
      } | null;
    }> | null;
  }>;
};

export type GetMatchInvoiceQueryVariables = Exact<{
  invoiceNumber: Scalars["String"];
  type: SaleTypeEnums;
}>;

export type GetMatchInvoiceQuery = {
  __typename?: "Query";
  getMatchInvoice?: {
    __typename?: "saleType";
    id: number;
    type: SaleTypeEnums;
    totalPrice: number;
    addLess: number;
    anyPayment: number;
    subTotal: number;
    returnSale: boolean;
    address: string;
    city: string;
    saleOnCash: boolean;
    miscellaneous?: string | null;
    date: any;
    invoiceNumber?: string | null;
    party?: {
      __typename?: "PartyType";
      id: number;
      partyCode: string;
      partyName: string;
      company?: string | null;
      phoneNumber?: string | null;
      city?: string | null;
      mobileNumber?: string | null;
      address?: string | null;
      balance?: number | null;
      debitBalance?: number | null;
      openingBalance?: number | null;
      creditBalance?: number | null;
      email?: string | null;
      sales?: Array<{
        __typename?: "saleType";
        id: number;
        type: SaleTypeEnums;
        totalPrice: number;
        addLess: number;
        anyPayment: number;
        subTotal: number;
        returnSale: boolean;
        address: string;
        city: string;
        saleOnCash: boolean;
        miscellaneous?: string | null;
        date: any;
        invoiceNumber?: string | null;
        party?: {
          __typename?: "PartyType";
          id: number;
          partyCode: string;
          partyName: string;
          company?: string | null;
          phoneNumber?: string | null;
          city?: string | null;
          mobileNumber?: string | null;
          address?: string | null;
          balance?: number | null;
          debitBalance?: number | null;
          openingBalance?: number | null;
          creditBalance?: number | null;
          email?: string | null;
        } | null;
        products?: Array<{
          __typename?: "Product";
          id: number;
          itemCode: string;
          quality?: QualityEnums | null;
          name: string;
          unitPurchasePrice: number;
          pcsOrBundle: number;
          meterOrSuit: number;
          amount: number;
          sumUpMeterOrSuitBalance?: number | null;
          sumUpPcsOrBundleBalance?: number | null;
        }> | null;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      }> | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    } | null;
    products?: Array<{
      __typename?: "Product";
      id: number;
      itemCode: string;
      quality?: QualityEnums | null;
      name: string;
      unitPurchasePrice: number;
      pcsOrBundle: number;
      meterOrSuit: number;
      amount: number;
      sumUpMeterOrSuitBalance?: number | null;
      sumUpPcsOrBundleBalance?: number | null;
      sale?: {
        __typename?: "saleType";
        id: number;
        type: SaleTypeEnums;
        totalPrice: number;
        addLess: number;
        anyPayment: number;
        subTotal: number;
        returnSale: boolean;
        address: string;
        city: string;
        saleOnCash: boolean;
        miscellaneous?: string | null;
        date: any;
        invoiceNumber?: string | null;
        party?: {
          __typename?: "PartyType";
          id: number;
          partyCode: string;
          partyName: string;
          company?: string | null;
          phoneNumber?: string | null;
          city?: string | null;
          mobileNumber?: string | null;
          address?: string | null;
          balance?: number | null;
          debitBalance?: number | null;
          openingBalance?: number | null;
          creditBalance?: number | null;
          email?: string | null;
        } | null;
        products?: Array<{
          __typename?: "Product";
          id: number;
          itemCode: string;
          quality?: QualityEnums | null;
          name: string;
          unitPurchasePrice: number;
          pcsOrBundle: number;
          meterOrSuit: number;
          amount: number;
          sumUpMeterOrSuitBalance?: number | null;
          sumUpPcsOrBundleBalance?: number | null;
        }> | null;
        timestamps: {
          __typename?: "Timestamps";
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        };
      } | null;
      challanItem?: Array<{
        __typename?: "ChallanItem";
        total: number;
        meterOrSuits: number;
        pcsOrBundle: number;
      }> | null;
      timestamps: {
        __typename?: "Timestamps";
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      };
    }> | null;
    timestamps: {
      __typename?: "Timestamps";
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    };
  } | null;
};

export type ProfitLossGetterQueryVariables = Exact<{ [key: string]: never }>;

export type ProfitLossGetterQuery = {
  __typename?: "Query";
  profitLossGetter: {
    __typename?: "ProfitLossType";
    payable?: number | null;
    stockInShop?: number | null;
    receivable?: number | null;
    cashInHand?: number | null;
    balanceInBank?: number | null;
    totalExpenses?: number | null;
    partner?: Array<{
      __typename?: "PartnersStat";
      name?: string | null;
      total?: number | null;
    }> | null;
    partnersWithdrawal?: Array<{
      __typename?: "PartnersStat";
      name?: string | null;
      total?: number | null;
    }> | null;
  };
};

export const SignInUserDocument = gql`
  mutation signInUser($SignInInput: SignInInput!) {
    signInUser(SignInInput: $SignInInput) {
      id
      name
      email
      password
      jwtToken
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type SignInUserMutationFn = Apollo.MutationFunction<
  SignInUserMutation,
  SignInUserMutationVariables
>;

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      SignInInput: // value for 'SignInInput'
 *   },
 * });
 */
export function useSignInUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInUserMutation,
    SignInUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInUserMutation, SignInUserMutationVariables>(
    SignInUserDocument,
    options,
  );
}
export type SignInUserMutationHookResult = ReturnType<
  typeof useSignInUserMutation
>;
export type SignInUserMutationResult =
  Apollo.MutationResult<SignInUserMutation>;
export type SignInUserMutationOptions = Apollo.BaseMutationOptions<
  SignInUserMutation,
  SignInUserMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser($RegisterUserInput: RegisterUserInput!) {
    registerUser(RegisterUserInput: $RegisterUserInput) {
      id
      name
      email
      jwtToken
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      RegisterUserInput: // value for 'RegisterUserInput'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const UpdateSlipDocument = gql`
  mutation updateSlip($updateReceiptInput: UpdateReceipt!) {
    updateSlip(updateReceiptInput: $updateReceiptInput) {
      id
      partyId
      partyDetail {
        id
        partyCode
        partyName
        company
        phoneNumber
        city
        mobileNumber
        address
        balance
        email
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      date
      amount
      slipType
      cashChequeBank
      signature
      sumOfRupees
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type UpdateSlipMutationFn = Apollo.MutationFunction<
  UpdateSlipMutation,
  UpdateSlipMutationVariables
>;

/**
 * __useUpdateSlipMutation__
 *
 * To run a mutation, you first call `useUpdateSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlipMutation, { data, loading, error }] = useUpdateSlipMutation({
 *   variables: {
 *      updateReceiptInput: // value for 'updateReceiptInput'
 *   },
 * });
 */
export function useUpdateSlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSlipMutation,
    UpdateSlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSlipMutation, UpdateSlipMutationVariables>(
    UpdateSlipDocument,
    options,
  );
}
export type UpdateSlipMutationHookResult = ReturnType<
  typeof useUpdateSlipMutation
>;
export type UpdateSlipMutationResult =
  Apollo.MutationResult<UpdateSlipMutation>;
export type UpdateSlipMutationOptions = Apollo.BaseMutationOptions<
  UpdateSlipMutation,
  UpdateSlipMutationVariables
>;
export const AddBalanceDocument = gql`
  mutation addBalance($createBalance: CreateBalanceInput!) {
    addBalance(createBalance: $createBalance) {
      id
      balance
      preBalance
      userId
    }
  }
`;
export type AddBalanceMutationFn = Apollo.MutationFunction<
  AddBalanceMutation,
  AddBalanceMutationVariables
>;

/**
 * __useAddBalanceMutation__
 *
 * To run a mutation, you first call `useAddBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBalanceMutation, { data, loading, error }] = useAddBalanceMutation({
 *   variables: {
 *      createBalance: // value for 'createBalance'
 *   },
 * });
 */
export function useAddBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBalanceMutation,
    AddBalanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBalanceMutation, AddBalanceMutationVariables>(
    AddBalanceDocument,
    options,
  );
}
export type AddBalanceMutationHookResult = ReturnType<
  typeof useAddBalanceMutation
>;
export type AddBalanceMutationResult =
  Apollo.MutationResult<AddBalanceMutation>;
export type AddBalanceMutationOptions = Apollo.BaseMutationOptions<
  AddBalanceMutation,
  AddBalanceMutationVariables
>;
export const CreateExpenseDocument = gql`
  mutation createExpense($createExpenseInput: ExpensesInput!) {
    createExpense(createExpenseInput: $createExpenseInput) {
      id
      expensesDetails {
        id
        category
        expenseCode
        paid
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      billNo
      total
      expenseDate
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type CreateExpenseMutationFn = Apollo.MutationFunction<
  CreateExpenseMutation,
  CreateExpenseMutationVariables
>;

/**
 * __useCreateExpenseMutation__
 *
 * To run a mutation, you first call `useCreateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseMutation, { data, loading, error }] = useCreateExpenseMutation({
 *   variables: {
 *      createExpenseInput: // value for 'createExpenseInput'
 *   },
 * });
 */
export function useCreateExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExpenseMutation,
    CreateExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExpenseMutation,
    CreateExpenseMutationVariables
  >(CreateExpenseDocument, options);
}
export type CreateExpenseMutationHookResult = ReturnType<
  typeof useCreateExpenseMutation
>;
export type CreateExpenseMutationResult =
  Apollo.MutationResult<CreateExpenseMutation>;
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<
  CreateExpenseMutation,
  CreateExpenseMutationVariables
>;
export const CreatePartyDocument = gql`
  mutation createParty($createPartyInput: CreatePartyInput!) {
    createParty(createPartyInput: $createPartyInput) {
      id
      partyCode
      partyName
      company
      phoneNumber
      mobileNumber
      address
      email
      balance
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type CreatePartyMutationFn = Apollo.MutationFunction<
  CreatePartyMutation,
  CreatePartyMutationVariables
>;

/**
 * __useCreatePartyMutation__
 *
 * To run a mutation, you first call `useCreatePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartyMutation, { data, loading, error }] = useCreatePartyMutation({
 *   variables: {
 *      createPartyInput: // value for 'createPartyInput'
 *   },
 * });
 */
export function useCreatePartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartyMutation,
    CreatePartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartyMutation, CreatePartyMutationVariables>(
    CreatePartyDocument,
    options,
  );
}
export type CreatePartyMutationHookResult = ReturnType<
  typeof useCreatePartyMutation
>;
export type CreatePartyMutationResult =
  Apollo.MutationResult<CreatePartyMutation>;
export type CreatePartyMutationOptions = Apollo.BaseMutationOptions<
  CreatePartyMutation,
  CreatePartyMutationVariables
>;
export const CreateProductDocument = gql`
  mutation createProduct($createProductInput: CreateProductInput!) {
    createProduct(createProductInput: $createProductInput) {
      id
      itemCode
      quality
      name
      unitPurchasePrice
      amount
      pcsOrBundle
      meterOrSuit
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      createProductInput: // value for 'createProductInput'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<
  typeof useCreateProductMutation
>;
export type CreateProductMutationResult =
  Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const CreateSaleDocument = gql`
  mutation createSale($createSaleInput: CreateSaleInput!) {
    createSale(createSaleInput: $createSaleInput) {
      id
      party {
        id
        partyCode
        sales {
          id
          totalPrice
          subTotal
          address
          city
          type
          saleOnCash
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
      }
    }
  }
`;
export type CreateSaleMutationFn = Apollo.MutationFunction<
  CreateSaleMutation,
  CreateSaleMutationVariables
>;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMutation, { data, loading, error }] = useCreateSaleMutation({
 *   variables: {
 *      createSaleInput: // value for 'createSaleInput'
 *   },
 * });
 */
export function useCreateSaleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSaleMutation,
    CreateSaleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(
    CreateSaleDocument,
    options,
  );
}
export type CreateSaleMutationHookResult = ReturnType<
  typeof useCreateSaleMutation
>;
export type CreateSaleMutationResult =
  Apollo.MutationResult<CreateSaleMutation>;
export type CreateSaleMutationOptions = Apollo.BaseMutationOptions<
  CreateSaleMutation,
  CreateSaleMutationVariables
>;
export const CreateSchedulerVoucherCodeDocument = gql`
  mutation createSchedulerVoucherCode(
    $inputSchedulerVoucherCodeList: InputSchedulerVoucherCodeList!
  ) {
    createSchedulerVoucherCode(
      inputSchedulerVoucherCodeList: $inputSchedulerVoucherCodeList
    ) {
      id
      title
      code
      type
      isScheduler
      amount
      outStandingAmount
      schedulerOf
      userId
    }
  }
`;
export type CreateSchedulerVoucherCodeMutationFn = Apollo.MutationFunction<
  CreateSchedulerVoucherCodeMutation,
  CreateSchedulerVoucherCodeMutationVariables
>;

/**
 * __useCreateSchedulerVoucherCodeMutation__
 *
 * To run a mutation, you first call `useCreateSchedulerVoucherCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchedulerVoucherCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchedulerVoucherCodeMutation, { data, loading, error }] = useCreateSchedulerVoucherCodeMutation({
 *   variables: {
 *      inputSchedulerVoucherCodeList: // value for 'inputSchedulerVoucherCodeList'
 *   },
 * });
 */
export function useCreateSchedulerVoucherCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSchedulerVoucherCodeMutation,
    CreateSchedulerVoucherCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSchedulerVoucherCodeMutation,
    CreateSchedulerVoucherCodeMutationVariables
  >(CreateSchedulerVoucherCodeDocument, options);
}
export type CreateSchedulerVoucherCodeMutationHookResult = ReturnType<
  typeof useCreateSchedulerVoucherCodeMutation
>;
export type CreateSchedulerVoucherCodeMutationResult =
  Apollo.MutationResult<CreateSchedulerVoucherCodeMutation>;
export type CreateSchedulerVoucherCodeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSchedulerVoucherCodeMutation,
    CreateSchedulerVoucherCodeMutationVariables
  >;
export const CreateSlipDocument = gql`
  mutation createSlip($createReceiptSlip: CreateReceiptSlipInput!) {
    createSlip(createReceiptSlip: $createReceiptSlip) {
      id
      partyId
      partyDetail {
        id
        partyCode
        partyName
        company
        phoneNumber
        city
        mobileNumber
        address
        balance
        email
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      date
      amount
      slipType
      cashChequeBank
      signature
      sumOfRupees
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type CreateSlipMutationFn = Apollo.MutationFunction<
  CreateSlipMutation,
  CreateSlipMutationVariables
>;

/**
 * __useCreateSlipMutation__
 *
 * To run a mutation, you first call `useCreateSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlipMutation, { data, loading, error }] = useCreateSlipMutation({
 *   variables: {
 *      createReceiptSlip: // value for 'createReceiptSlip'
 *   },
 * });
 */
export function useCreateSlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSlipMutation,
    CreateSlipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSlipMutation, CreateSlipMutationVariables>(
    CreateSlipDocument,
    options,
  );
}
export type CreateSlipMutationHookResult = ReturnType<
  typeof useCreateSlipMutation
>;
export type CreateSlipMutationResult =
  Apollo.MutationResult<CreateSlipMutation>;
export type CreateSlipMutationOptions = Apollo.BaseMutationOptions<
  CreateSlipMutation,
  CreateSlipMutationVariables
>;
export const CreateVoucherDocument = gql`
  mutation createVoucher($createVoucherInput: VoucherInput!) {
    createVoucher(createVoucherInput: $createVoucherInput) {
      id
      voucherItem {
        id
        voucherCodeDetail {
          id
          title
          code
          userId
        }
        recd
        paid
      }
      totalPaid
      totalReceived
      date
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type CreateVoucherMutationFn = Apollo.MutationFunction<
  CreateVoucherMutation,
  CreateVoucherMutationVariables
>;

/**
 * __useCreateVoucherMutation__
 *
 * To run a mutation, you first call `useCreateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoucherMutation, { data, loading, error }] = useCreateVoucherMutation({
 *   variables: {
 *      createVoucherInput: // value for 'createVoucherInput'
 *   },
 * });
 */
export function useCreateVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVoucherMutation,
    CreateVoucherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVoucherMutation,
    CreateVoucherMutationVariables
  >(CreateVoucherDocument, options);
}
export type CreateVoucherMutationHookResult = ReturnType<
  typeof useCreateVoucherMutation
>;
export type CreateVoucherMutationResult =
  Apollo.MutationResult<CreateVoucherMutation>;
export type CreateVoucherMutationOptions = Apollo.BaseMutationOptions<
  CreateVoucherMutation,
  CreateVoucherMutationVariables
>;
export const CreateVoucherCodeDocument = gql`
  mutation createVoucherCode($inputVoucherCodeList: InputVoucherCodeList!) {
    createVoucherCode(inputVoucherCodeList: $inputVoucherCodeList) {
      id
      title
      code
      userId
    }
  }
`;
export type CreateVoucherCodeMutationFn = Apollo.MutationFunction<
  CreateVoucherCodeMutation,
  CreateVoucherCodeMutationVariables
>;

/**
 * __useCreateVoucherCodeMutation__
 *
 * To run a mutation, you first call `useCreateVoucherCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoucherCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoucherCodeMutation, { data, loading, error }] = useCreateVoucherCodeMutation({
 *   variables: {
 *      inputVoucherCodeList: // value for 'inputVoucherCodeList'
 *   },
 * });
 */
export function useCreateVoucherCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVoucherCodeMutation,
    CreateVoucherCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVoucherCodeMutation,
    CreateVoucherCodeMutationVariables
  >(CreateVoucherCodeDocument, options);
}
export type CreateVoucherCodeMutationHookResult = ReturnType<
  typeof useCreateVoucherCodeMutation
>;
export type CreateVoucherCodeMutationResult =
  Apollo.MutationResult<CreateVoucherCodeMutation>;
export type CreateVoucherCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateVoucherCodeMutation,
  CreateVoucherCodeMutationVariables
>;
export const DeleteExpenseWithIdsDocument = gql`
  mutation DeleteExpenseWithIds($deleteExpenseWithIds: DeleteExpenseWithIds!) {
    DeleteExpenseWithIds(deleteExpenseWithIds: $deleteExpenseWithIds) {
      id
      billNo
      total
      expenseDate
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type DeleteExpenseWithIdsMutationFn = Apollo.MutationFunction<
  DeleteExpenseWithIdsMutation,
  DeleteExpenseWithIdsMutationVariables
>;

/**
 * __useDeleteExpenseWithIdsMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseWithIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseWithIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseWithIdsMutation, { data, loading, error }] = useDeleteExpenseWithIdsMutation({
 *   variables: {
 *      deleteExpenseWithIds: // value for 'deleteExpenseWithIds'
 *   },
 * });
 */
export function useDeleteExpenseWithIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExpenseWithIdsMutation,
    DeleteExpenseWithIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExpenseWithIdsMutation,
    DeleteExpenseWithIdsMutationVariables
  >(DeleteExpenseWithIdsDocument, options);
}
export type DeleteExpenseWithIdsMutationHookResult = ReturnType<
  typeof useDeleteExpenseWithIdsMutation
>;
export type DeleteExpenseWithIdsMutationResult =
  Apollo.MutationResult<DeleteExpenseWithIdsMutation>;
export type DeleteExpenseWithIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteExpenseWithIdsMutation,
  DeleteExpenseWithIdsMutationVariables
>;
export const DeleteSalesWithIdsDocument = gql`
  mutation deleteSalesWithIds($deleteSaleWithIds: DeleteSaleWithIds!) {
    deleteSalesWithIds(deleteSaleWithIds: $deleteSaleWithIds) {
      id
      totalPrice
      addLess
      anyPayment
      subTotal
      returnSale
      address
      city
      saleOnCash
      miscellaneous
      date
      invoiceNumber
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type DeleteSalesWithIdsMutationFn = Apollo.MutationFunction<
  DeleteSalesWithIdsMutation,
  DeleteSalesWithIdsMutationVariables
>;

/**
 * __useDeleteSalesWithIdsMutation__
 *
 * To run a mutation, you first call `useDeleteSalesWithIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesWithIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesWithIdsMutation, { data, loading, error }] = useDeleteSalesWithIdsMutation({
 *   variables: {
 *      deleteSaleWithIds: // value for 'deleteSaleWithIds'
 *   },
 * });
 */
export function useDeleteSalesWithIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSalesWithIdsMutation,
    DeleteSalesWithIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSalesWithIdsMutation,
    DeleteSalesWithIdsMutationVariables
  >(DeleteSalesWithIdsDocument, options);
}
export type DeleteSalesWithIdsMutationHookResult = ReturnType<
  typeof useDeleteSalesWithIdsMutation
>;
export type DeleteSalesWithIdsMutationResult =
  Apollo.MutationResult<DeleteSalesWithIdsMutation>;
export type DeleteSalesWithIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSalesWithIdsMutation,
  DeleteSalesWithIdsMutationVariables
>;
export const DeletePartiesWithIdsDocument = gql`
  mutation deletePartiesWithIds($deletewithids: DeletePartyWithIds!) {
    deletePartiesWithIds(deletewithids: $deletewithids) {
      id
      partyCode
      partyName
      company
      phoneNumber
      city
      mobileNumber
      address
      email
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type DeletePartiesWithIdsMutationFn = Apollo.MutationFunction<
  DeletePartiesWithIdsMutation,
  DeletePartiesWithIdsMutationVariables
>;

/**
 * __useDeletePartiesWithIdsMutation__
 *
 * To run a mutation, you first call `useDeletePartiesWithIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartiesWithIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartiesWithIdsMutation, { data, loading, error }] = useDeletePartiesWithIdsMutation({
 *   variables: {
 *      deletewithids: // value for 'deletewithids'
 *   },
 * });
 */
export function useDeletePartiesWithIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePartiesWithIdsMutation,
    DeletePartiesWithIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePartiesWithIdsMutation,
    DeletePartiesWithIdsMutationVariables
  >(DeletePartiesWithIdsDocument, options);
}
export type DeletePartiesWithIdsMutationHookResult = ReturnType<
  typeof useDeletePartiesWithIdsMutation
>;
export type DeletePartiesWithIdsMutationResult =
  Apollo.MutationResult<DeletePartiesWithIdsMutation>;
export type DeletePartiesWithIdsMutationOptions = Apollo.BaseMutationOptions<
  DeletePartiesWithIdsMutation,
  DeletePartiesWithIdsMutationVariables
>;
export const DeleteProductsWithIdsDocument = gql`
  mutation deleteProductsWithIds($deletewithids: DeleteProductWithIds!) {
    deleteProductsWithIds(deletewithids: $deletewithids) {
      id
      itemCode
      quality
      name
      unitPurchasePrice
      amount
      pcsOrBundle
      meterOrSuit
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type DeleteProductsWithIdsMutationFn = Apollo.MutationFunction<
  DeleteProductsWithIdsMutation,
  DeleteProductsWithIdsMutationVariables
>;

/**
 * __useDeleteProductsWithIdsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsWithIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsWithIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsWithIdsMutation, { data, loading, error }] = useDeleteProductsWithIdsMutation({
 *   variables: {
 *      deletewithids: // value for 'deletewithids'
 *   },
 * });
 */
export function useDeleteProductsWithIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductsWithIdsMutation,
    DeleteProductsWithIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProductsWithIdsMutation,
    DeleteProductsWithIdsMutationVariables
  >(DeleteProductsWithIdsDocument, options);
}
export type DeleteProductsWithIdsMutationHookResult = ReturnType<
  typeof useDeleteProductsWithIdsMutation
>;
export type DeleteProductsWithIdsMutationResult =
  Apollo.MutationResult<DeleteProductsWithIdsMutation>;
export type DeleteProductsWithIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductsWithIdsMutation,
  DeleteProductsWithIdsMutationVariables
>;
export const DeleteReceiptDocument = gql`
  mutation deleteReceipt($id: Int!) {
    deleteReceipt(id: $id) {
      id
      partyId
      partyDetail {
        id
        partyCode
        partyName
        company
        phoneNumber
        city
        mobileNumber
        address
        balance
        email
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      date
      amount
      slipType
      cashChequeBank
      signature
      sumOfRupees
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type DeleteReceiptMutationFn = Apollo.MutationFunction<
  DeleteReceiptMutation,
  DeleteReceiptMutationVariables
>;

/**
 * __useDeleteReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceiptMutation, { data, loading, error }] = useDeleteReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReceiptMutation,
    DeleteReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReceiptMutation,
    DeleteReceiptMutationVariables
  >(DeleteReceiptDocument, options);
}
export type DeleteReceiptMutationHookResult = ReturnType<
  typeof useDeleteReceiptMutation
>;
export type DeleteReceiptMutationResult =
  Apollo.MutationResult<DeleteReceiptMutation>;
export type DeleteReceiptMutationOptions = Apollo.BaseMutationOptions<
  DeleteReceiptMutation,
  DeleteReceiptMutationVariables
>;
export const DeleteVoucherWithIdsDocument = gql`
  mutation DeleteVoucherWithIds($deleteVoucherWithIds: DeleteVoucherWithIds!) {
    DeleteVoucherWithIds(deleteVoucherWithIds: $deleteVoucherWithIds) {
      id
    }
  }
`;
export type DeleteVoucherWithIdsMutationFn = Apollo.MutationFunction<
  DeleteVoucherWithIdsMutation,
  DeleteVoucherWithIdsMutationVariables
>;

/**
 * __useDeleteVoucherWithIdsMutation__
 *
 * To run a mutation, you first call `useDeleteVoucherWithIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoucherWithIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoucherWithIdsMutation, { data, loading, error }] = useDeleteVoucherWithIdsMutation({
 *   variables: {
 *      deleteVoucherWithIds: // value for 'deleteVoucherWithIds'
 *   },
 * });
 */
export function useDeleteVoucherWithIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVoucherWithIdsMutation,
    DeleteVoucherWithIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVoucherWithIdsMutation,
    DeleteVoucherWithIdsMutationVariables
  >(DeleteVoucherWithIdsDocument, options);
}
export type DeleteVoucherWithIdsMutationHookResult = ReturnType<
  typeof useDeleteVoucherWithIdsMutation
>;
export type DeleteVoucherWithIdsMutationResult =
  Apollo.MutationResult<DeleteVoucherWithIdsMutation>;
export type DeleteVoucherWithIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteVoucherWithIdsMutation,
  DeleteVoucherWithIdsMutationVariables
>;
export const DeleteVoucherCodeDocument = gql`
  mutation deleteVoucherCode($deleteVoucherWithIds: DeleteVoucherWithIds!) {
    deleteVoucherCode(deleteVoucherWithIds: $deleteVoucherWithIds) {
      id
      title
      code
      type
      userId
    }
  }
`;
export type DeleteVoucherCodeMutationFn = Apollo.MutationFunction<
  DeleteVoucherCodeMutation,
  DeleteVoucherCodeMutationVariables
>;

/**
 * __useDeleteVoucherCodeMutation__
 *
 * To run a mutation, you first call `useDeleteVoucherCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoucherCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoucherCodeMutation, { data, loading, error }] = useDeleteVoucherCodeMutation({
 *   variables: {
 *      deleteVoucherWithIds: // value for 'deleteVoucherWithIds'
 *   },
 * });
 */
export function useDeleteVoucherCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVoucherCodeMutation,
    DeleteVoucherCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVoucherCodeMutation,
    DeleteVoucherCodeMutationVariables
  >(DeleteVoucherCodeDocument, options);
}
export type DeleteVoucherCodeMutationHookResult = ReturnType<
  typeof useDeleteVoucherCodeMutation
>;
export type DeleteVoucherCodeMutationResult =
  Apollo.MutationResult<DeleteVoucherCodeMutation>;
export type DeleteVoucherCodeMutationOptions = Apollo.BaseMutationOptions<
  DeleteVoucherCodeMutation,
  DeleteVoucherCodeMutationVariables
>;
export const EditProductByIdDocument = gql`
  mutation editProductById($editProductInput: EditProductInput!) {
    editProductById(editProductInput: $editProductInput) {
      id
      itemCode
      quality
      name
      unitPurchasePrice
      amount
      pcsOrBundle
      meterOrSuit
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type EditProductByIdMutationFn = Apollo.MutationFunction<
  EditProductByIdMutation,
  EditProductByIdMutationVariables
>;

/**
 * __useEditProductByIdMutation__
 *
 * To run a mutation, you first call `useEditProductByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductByIdMutation, { data, loading, error }] = useEditProductByIdMutation({
 *   variables: {
 *      editProductInput: // value for 'editProductInput'
 *   },
 * });
 */
export function useEditProductByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProductByIdMutation,
    EditProductByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProductByIdMutation,
    EditProductByIdMutationVariables
  >(EditProductByIdDocument, options);
}
export type EditProductByIdMutationHookResult = ReturnType<
  typeof useEditProductByIdMutation
>;
export type EditProductByIdMutationResult =
  Apollo.MutationResult<EditProductByIdMutation>;
export type EditProductByIdMutationOptions = Apollo.BaseMutationOptions<
  EditProductByIdMutation,
  EditProductByIdMutationVariables
>;
export const EditPartyByIdDocument = gql`
  mutation editPartyById($editPartyInput: EditPartyInput!) {
    editPartyById(editPartyInput: $editPartyInput) {
      id
      partyCode
      partyName
      company
      phoneNumber
      city
      mobileNumber
      address
      balance
      email
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type EditPartyByIdMutationFn = Apollo.MutationFunction<
  EditPartyByIdMutation,
  EditPartyByIdMutationVariables
>;

/**
 * __useEditPartyByIdMutation__
 *
 * To run a mutation, you first call `useEditPartyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPartyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPartyByIdMutation, { data, loading, error }] = useEditPartyByIdMutation({
 *   variables: {
 *      editPartyInput: // value for 'editPartyInput'
 *   },
 * });
 */
export function useEditPartyByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPartyByIdMutation,
    EditPartyByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditPartyByIdMutation,
    EditPartyByIdMutationVariables
  >(EditPartyByIdDocument, options);
}
export type EditPartyByIdMutationHookResult = ReturnType<
  typeof useEditPartyByIdMutation
>;
export type EditPartyByIdMutationResult =
  Apollo.MutationResult<EditPartyByIdMutation>;
export type EditPartyByIdMutationOptions = Apollo.BaseMutationOptions<
  EditPartyByIdMutation,
  EditPartyByIdMutationVariables
>;
export const EditSaleByIdDocument = gql`
  mutation editSaleById($editSaleInput: EditSaleInput!) {
    editSaleById(editSaleInput: $editSaleInput) {
      id
      type
      totalPrice
      addLess
      anyPayment
      subTotal
      returnSale
      address
      city
      saleOnCash
      miscellaneous
      date
      invoiceNumber
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type EditSaleByIdMutationFn = Apollo.MutationFunction<
  EditSaleByIdMutation,
  EditSaleByIdMutationVariables
>;

/**
 * __useEditSaleByIdMutation__
 *
 * To run a mutation, you first call `useEditSaleByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSaleByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSaleByIdMutation, { data, loading, error }] = useEditSaleByIdMutation({
 *   variables: {
 *      editSaleInput: // value for 'editSaleInput'
 *   },
 * });
 */
export function useEditSaleByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSaleByIdMutation,
    EditSaleByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSaleByIdMutation,
    EditSaleByIdMutationVariables
  >(EditSaleByIdDocument, options);
}
export type EditSaleByIdMutationHookResult = ReturnType<
  typeof useEditSaleByIdMutation
>;
export type EditSaleByIdMutationResult =
  Apollo.MutationResult<EditSaleByIdMutation>;
export type EditSaleByIdMutationOptions = Apollo.BaseMutationOptions<
  EditSaleByIdMutation,
  EditSaleByIdMutationVariables
>;
export const EditVoucherInputDocument = gql`
  mutation editVoucherInput($editVoucherInput: EditVoucherDetailsInput!) {
    editVoucherInput(editVoucherInput: $editVoucherInput) {
      id
      voucherItem {
        id
        voucherCodeDetail {
          id
          title
          code
          type
          userId
        }
        description
        recd
        paid
      }
      totalPaid
      totalReceived
      remarks
      signature
      voucherNumber
      date
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export type EditVoucherInputMutationFn = Apollo.MutationFunction<
  EditVoucherInputMutation,
  EditVoucherInputMutationVariables
>;

/**
 * __useEditVoucherInputMutation__
 *
 * To run a mutation, you first call `useEditVoucherInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVoucherInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVoucherInputMutation, { data, loading, error }] = useEditVoucherInputMutation({
 *   variables: {
 *      editVoucherInput: // value for 'editVoucherInput'
 *   },
 * });
 */
export function useEditVoucherInputMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditVoucherInputMutation,
    EditVoucherInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditVoucherInputMutation,
    EditVoucherInputMutationVariables
  >(EditVoucherInputDocument, options);
}
export type EditVoucherInputMutationHookResult = ReturnType<
  typeof useEditVoucherInputMutation
>;
export type EditVoucherInputMutationResult =
  Apollo.MutationResult<EditVoucherInputMutation>;
export type EditVoucherInputMutationOptions = Apollo.BaseMutationOptions<
  EditVoucherInputMutation,
  EditVoucherInputMutationVariables
>;
export const UpdateVoucherCodeDocument = gql`
  mutation updateVoucherCode($updateVoucherCodeInput: EditVoucherCodeInput!) {
    updateVoucherCode(updateVoucherCodeInput: $updateVoucherCodeInput) {
      id
      title
      code
      type
      userId
    }
  }
`;
export type UpdateVoucherCodeMutationFn = Apollo.MutationFunction<
  UpdateVoucherCodeMutation,
  UpdateVoucherCodeMutationVariables
>;

/**
 * __useUpdateVoucherCodeMutation__
 *
 * To run a mutation, you first call `useUpdateVoucherCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoucherCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoucherCodeMutation, { data, loading, error }] = useUpdateVoucherCodeMutation({
 *   variables: {
 *      updateVoucherCodeInput: // value for 'updateVoucherCodeInput'
 *   },
 * });
 */
export function useUpdateVoucherCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVoucherCodeMutation,
    UpdateVoucherCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVoucherCodeMutation,
    UpdateVoucherCodeMutationVariables
  >(UpdateVoucherCodeDocument, options);
}
export type UpdateVoucherCodeMutationHookResult = ReturnType<
  typeof useUpdateVoucherCodeMutation
>;
export type UpdateVoucherCodeMutationResult =
  Apollo.MutationResult<UpdateVoucherCodeMutation>;
export type UpdateVoucherCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateVoucherCodeMutation,
  UpdateVoucherCodeMutationVariables
>;
export const WhoAmIDocument = gql`
  query whoAmI {
    whoAmI {
      id
      name
      email
      password
      jwtToken
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options,
  );
}
export function useWhoAmILazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options,
  );
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>;
export const FindVoucherCodeByCodeDocument = gql`
  query findVoucherCodeByCode($voucherCode: String!) {
    findVoucherCodeByCode(voucherCode: $voucherCode) {
      id
      voucherItem {
        id
        voucherCodeDetail {
          id
          title
          code
          type
          userId
        }
        description
        recd
        paid
      }
      totalPaid
      totalReceived
      remarks
      signature
      voucherNumber
      date
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useFindVoucherCodeByCodeQuery__
 *
 * To run a query within a React component, call `useFindVoucherCodeByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVoucherCodeByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVoucherCodeByCodeQuery({
 *   variables: {
 *      voucherCode: // value for 'voucherCode'
 *   },
 * });
 */
export function useFindVoucherCodeByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindVoucherCodeByCodeQuery,
    FindVoucherCodeByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindVoucherCodeByCodeQuery,
    FindVoucherCodeByCodeQueryVariables
  >(FindVoucherCodeByCodeDocument, options);
}
export function useFindVoucherCodeByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindVoucherCodeByCodeQuery,
    FindVoucherCodeByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindVoucherCodeByCodeQuery,
    FindVoucherCodeByCodeQueryVariables
  >(FindVoucherCodeByCodeDocument, options);
}
export type FindVoucherCodeByCodeQueryHookResult = ReturnType<
  typeof useFindVoucherCodeByCodeQuery
>;
export type FindVoucherCodeByCodeLazyQueryHookResult = ReturnType<
  typeof useFindVoucherCodeByCodeLazyQuery
>;
export type FindVoucherCodeByCodeQueryResult = Apollo.QueryResult<
  FindVoucherCodeByCodeQuery,
  FindVoucherCodeByCodeQueryVariables
>;
export const GetVoucherCodeByIdDocument = gql`
  query getVoucherCodeById($id: Float!) {
    getVoucherCodeById(id: $id) {
      id
      title
      code
      type
      isScheduler
      amount
      outStandingAmount
      schedulerOf
      voucherItem {
        id
        voucherCode
        description
        recd
        paid
        effectChainAmount
        voucher {
          id
          voucherNumber
          date
        }
      }
      userId
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetVoucherCodeByIdQuery__
 *
 * To run a query within a React component, call `useGetVoucherCodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoucherCodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoucherCodeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVoucherCodeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVoucherCodeByIdQuery,
    GetVoucherCodeByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVoucherCodeByIdQuery,
    GetVoucherCodeByIdQueryVariables
  >(GetVoucherCodeByIdDocument, options);
}
export function useGetVoucherCodeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVoucherCodeByIdQuery,
    GetVoucherCodeByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVoucherCodeByIdQuery,
    GetVoucherCodeByIdQueryVariables
  >(GetVoucherCodeByIdDocument, options);
}
export type GetVoucherCodeByIdQueryHookResult = ReturnType<
  typeof useGetVoucherCodeByIdQuery
>;
export type GetVoucherCodeByIdLazyQueryHookResult = ReturnType<
  typeof useGetVoucherCodeByIdLazyQuery
>;
export type GetVoucherCodeByIdQueryResult = Apollo.QueryResult<
  GetVoucherCodeByIdQuery,
  GetVoucherCodeByIdQueryVariables
>;
export const GetBalanceHistoryDocument = gql`
  query getBalanceHistory($page: Int) {
    getBalanceHistory(page: $page) {
      items {
        id
        balance
        preBalance
        userId
        evaluateAt
        effectAmount
      }
      total
    }
  }
`;

/**
 * __useGetBalanceHistoryQuery__
 *
 * To run a query within a React component, call `useGetBalanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetBalanceHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBalanceHistoryQuery,
    GetBalanceHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBalanceHistoryQuery,
    GetBalanceHistoryQueryVariables
  >(GetBalanceHistoryDocument, options);
}
export function useGetBalanceHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBalanceHistoryQuery,
    GetBalanceHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBalanceHistoryQuery,
    GetBalanceHistoryQueryVariables
  >(GetBalanceHistoryDocument, options);
}
export type GetBalanceHistoryQueryHookResult = ReturnType<
  typeof useGetBalanceHistoryQuery
>;
export type GetBalanceHistoryLazyQueryHookResult = ReturnType<
  typeof useGetBalanceHistoryLazyQuery
>;
export type GetBalanceHistoryQueryResult = Apollo.QueryResult<
  GetBalanceHistoryQuery,
  GetBalanceHistoryQueryVariables
>;
export const GetCashBookDocument = gql`
  query getCashBook($date: DateTime) {
    getCashBook(date: $date) {
      sales {
        id
        type
        totalPrice
        date
        saleOnCash
        invoiceNumber
        party {
          id
          partyCode
          partyName
          company
          city
          phoneNumber
          address
          email
        }
      }
      purchases {
        id
        type
        totalPrice
        date
        saleOnCash
        invoiceNumber
        party {
          id
          partyCode
          partyName
          company
          city
          phoneNumber
          address
          email
        }
      }
      receipts {
        id
        partyId
        userId
        slipCode
        partyDetail {
          id
          partyCode
          partyName
          company
          city
          phoneNumber
          address
          email
        }
        date
        onAccountOf
        slipType
        cashChequeBank
        signature
        sumOfRupees
        amount
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      payments {
        id
        partyId
        userId
        slipCode
        partyDetail {
          id
          partyCode
          partyName
          city
          company
          phoneNumber
          address
          email
        }
        date
        onAccountOf
        slipType
        cashChequeBank
        signature
        sumOfRupees
        amount
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      vouchers {
        id
        voucherItem {
          id
          voucherCodeDetail {
            id
            title
            code
            type
            userId
          }
          description
          recd
          paid
        }
        totalPaid
        totalReceived
        remarks
        signature
        voucherNumber
        date
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      bfBalance
    }
  }
`;

/**
 * __useGetCashBookQuery__
 *
 * To run a query within a React component, call `useGetCashBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashBookQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCashBookQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCashBookQuery,
    GetCashBookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCashBookQuery, GetCashBookQueryVariables>(
    GetCashBookDocument,
    options,
  );
}
export function useGetCashBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCashBookQuery,
    GetCashBookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCashBookQuery, GetCashBookQueryVariables>(
    GetCashBookDocument,
    options,
  );
}
export type GetCashBookQueryHookResult = ReturnType<typeof useGetCashBookQuery>;
export type GetCashBookLazyQueryHookResult = ReturnType<
  typeof useGetCashBookLazyQuery
>;
export type GetCashBookQueryResult = Apollo.QueryResult<
  GetCashBookQuery,
  GetCashBookQueryVariables
>;
export const GetCurrentBalanceDocument = gql`
  query getCurrentBalance {
    getCurrentBalance {
      id
      balance
      preBalance
      userId
    }
  }
`;

/**
 * __useGetCurrentBalanceQuery__
 *
 * To run a query within a React component, call `useGetCurrentBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentBalanceQuery,
    GetCurrentBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentBalanceQuery,
    GetCurrentBalanceQueryVariables
  >(GetCurrentBalanceDocument, options);
}
export function useGetCurrentBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentBalanceQuery,
    GetCurrentBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentBalanceQuery,
    GetCurrentBalanceQueryVariables
  >(GetCurrentBalanceDocument, options);
}
export type GetCurrentBalanceQueryHookResult = ReturnType<
  typeof useGetCurrentBalanceQuery
>;
export type GetCurrentBalanceLazyQueryHookResult = ReturnType<
  typeof useGetCurrentBalanceLazyQuery
>;
export type GetCurrentBalanceQueryResult = Apollo.QueryResult<
  GetCurrentBalanceQuery,
  GetCurrentBalanceQueryVariables
>;
export const GetInitialBalanceAndCurrentBalanceDocument = gql`
  query getInitialBalanceAndCurrentBalance {
    getInitialBalanceAndCurrentBalance {
      currentBalance {
        id
        balance
        preBalance
        userId
      }
      initialBalance {
        id
        balance
        preBalance
        userId
      }
    }
  }
`;

/**
 * __useGetInitialBalanceAndCurrentBalanceQuery__
 *
 * To run a query within a React component, call `useGetInitialBalanceAndCurrentBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialBalanceAndCurrentBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialBalanceAndCurrentBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialBalanceAndCurrentBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInitialBalanceAndCurrentBalanceQuery,
    GetInitialBalanceAndCurrentBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInitialBalanceAndCurrentBalanceQuery,
    GetInitialBalanceAndCurrentBalanceQueryVariables
  >(GetInitialBalanceAndCurrentBalanceDocument, options);
}
export function useGetInitialBalanceAndCurrentBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInitialBalanceAndCurrentBalanceQuery,
    GetInitialBalanceAndCurrentBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInitialBalanceAndCurrentBalanceQuery,
    GetInitialBalanceAndCurrentBalanceQueryVariables
  >(GetInitialBalanceAndCurrentBalanceDocument, options);
}
export type GetInitialBalanceAndCurrentBalanceQueryHookResult = ReturnType<
  typeof useGetInitialBalanceAndCurrentBalanceQuery
>;
export type GetInitialBalanceAndCurrentBalanceLazyQueryHookResult = ReturnType<
  typeof useGetInitialBalanceAndCurrentBalanceLazyQuery
>;
export type GetInitialBalanceAndCurrentBalanceQueryResult = Apollo.QueryResult<
  GetInitialBalanceAndCurrentBalanceQuery,
  GetInitialBalanceAndCurrentBalanceQueryVariables
>;
export const FilterExpenseDocument = gql`
  query filterExpense($getExpenseInput: getExpenseInput!) {
    filterExpense(getExpenseInput: $getExpenseInput) {
      items {
        id
        expensesDetails {
          id
          category
          expenseCode
          paid
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
        billNo
        total
        expenseDate
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
    }
  }
`;

/**
 * __useFilterExpenseQuery__
 *
 * To run a query within a React component, call `useFilterExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterExpenseQuery({
 *   variables: {
 *      getExpenseInput: // value for 'getExpenseInput'
 *   },
 * });
 */
export function useFilterExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterExpenseQuery,
    FilterExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterExpenseQuery, FilterExpenseQueryVariables>(
    FilterExpenseDocument,
    options,
  );
}
export function useFilterExpenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterExpenseQuery,
    FilterExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterExpenseQuery, FilterExpenseQueryVariables>(
    FilterExpenseDocument,
    options,
  );
}
export type FilterExpenseQueryHookResult = ReturnType<
  typeof useFilterExpenseQuery
>;
export type FilterExpenseLazyQueryHookResult = ReturnType<
  typeof useFilterExpenseLazyQuery
>;
export type FilterExpenseQueryResult = Apollo.QueryResult<
  FilterExpenseQuery,
  FilterExpenseQueryVariables
>;
export const GetExpenseWithIdDocument = gql`
  query getExpenseWithId($id: Float!) {
    getExpenseWithId(id: $id) {
      id
      expensesDetails {
        id
        category
        expenseCode
        paid
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      billNo
      total
      expenseDate
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetExpenseWithIdQuery__
 *
 * To run a query within a React component, call `useGetExpenseWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpenseWithIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExpenseWithIdQuery,
    GetExpenseWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpenseWithIdQuery, GetExpenseWithIdQueryVariables>(
    GetExpenseWithIdDocument,
    options,
  );
}
export function useGetExpenseWithIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseWithIdQuery,
    GetExpenseWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExpenseWithIdQuery,
    GetExpenseWithIdQueryVariables
  >(GetExpenseWithIdDocument, options);
}
export type GetExpenseWithIdQueryHookResult = ReturnType<
  typeof useGetExpenseWithIdQuery
>;
export type GetExpenseWithIdLazyQueryHookResult = ReturnType<
  typeof useGetExpenseWithIdLazyQuery
>;
export type GetExpenseWithIdQueryResult = Apollo.QueryResult<
  GetExpenseWithIdQuery,
  GetExpenseWithIdQueryVariables
>;
export const GetLatestExpenseDocument = gql`
  query getLatestExpense {
    getLatestExpense {
      id
      billNo
    }
  }
`;

/**
 * __useGetLatestExpenseQuery__
 *
 * To run a query within a React component, call `useGetLatestExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestExpenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestExpenseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestExpenseQuery,
    GetLatestExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestExpenseQuery, GetLatestExpenseQueryVariables>(
    GetLatestExpenseDocument,
    options,
  );
}
export function useGetLatestExpenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestExpenseQuery,
    GetLatestExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestExpenseQuery,
    GetLatestExpenseQueryVariables
  >(GetLatestExpenseDocument, options);
}
export type GetLatestExpenseQueryHookResult = ReturnType<
  typeof useGetLatestExpenseQuery
>;
export type GetLatestExpenseLazyQueryHookResult = ReturnType<
  typeof useGetLatestExpenseLazyQuery
>;
export type GetLatestExpenseQueryResult = Apollo.QueryResult<
  GetLatestExpenseQuery,
  GetLatestExpenseQueryVariables
>;
export const GetLatestPartyDocument = gql`
  query getLatestParty {
    getLatestParty {
      id
      partyCode
    }
  }
`;

/**
 * __useGetLatestPartyQuery__
 *
 * To run a query within a React component, call `useGetLatestPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPartyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestPartyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestPartyQuery,
    GetLatestPartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestPartyQuery, GetLatestPartyQueryVariables>(
    GetLatestPartyDocument,
    options,
  );
}
export function useGetLatestPartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestPartyQuery,
    GetLatestPartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestPartyQuery, GetLatestPartyQueryVariables>(
    GetLatestPartyDocument,
    options,
  );
}
export type GetLatestPartyQueryHookResult = ReturnType<
  typeof useGetLatestPartyQuery
>;
export type GetLatestPartyLazyQueryHookResult = ReturnType<
  typeof useGetLatestPartyLazyQuery
>;
export type GetLatestPartyQueryResult = Apollo.QueryResult<
  GetLatestPartyQuery,
  GetLatestPartyQueryVariables
>;
export const GetLatestProductDocument = gql`
  query getLatestProduct {
    getLatestProduct {
      id
      itemCode
    }
  }
`;

/**
 * __useGetLatestProductQuery__
 *
 * To run a query within a React component, call `useGetLatestProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestProductQuery,
    GetLatestProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestProductQuery, GetLatestProductQueryVariables>(
    GetLatestProductDocument,
    options,
  );
}
export function useGetLatestProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestProductQuery,
    GetLatestProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestProductQuery,
    GetLatestProductQueryVariables
  >(GetLatestProductDocument, options);
}
export type GetLatestProductQueryHookResult = ReturnType<
  typeof useGetLatestProductQuery
>;
export type GetLatestProductLazyQueryHookResult = ReturnType<
  typeof useGetLatestProductLazyQuery
>;
export type GetLatestProductQueryResult = Apollo.QueryResult<
  GetLatestProductQuery,
  GetLatestProductQueryVariables
>;
export const GetLatestSaleDocument = gql`
  query getLatestSale($type: saleTypeEnums!) {
    getLatestSale(type: $type) {
      id
      type
      totalPrice
      addLess
      anyPayment
      subTotal
      address
      city
      saleOnCash
      miscellaneous
      date
      invoiceNumber
    }
  }
`;

/**
 * __useGetLatestSaleQuery__
 *
 * To run a query within a React component, call `useGetLatestSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSaleQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestSaleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestSaleQuery,
    GetLatestSaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestSaleQuery, GetLatestSaleQueryVariables>(
    GetLatestSaleDocument,
    options,
  );
}
export function useGetLatestSaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestSaleQuery,
    GetLatestSaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestSaleQuery, GetLatestSaleQueryVariables>(
    GetLatestSaleDocument,
    options,
  );
}
export type GetLatestSaleQueryHookResult = ReturnType<
  typeof useGetLatestSaleQuery
>;
export type GetLatestSaleLazyQueryHookResult = ReturnType<
  typeof useGetLatestSaleLazyQuery
>;
export type GetLatestSaleQueryResult = Apollo.QueryResult<
  GetLatestSaleQuery,
  GetLatestSaleQueryVariables
>;
export const GetLatestIdOfSlipDocument = gql`
  query getLatestIdOfSlip($FindLatestSlip: FindLatestSlip!) {
    getLatestIdOfSlip(FindLatestSlip: $FindLatestSlip) {
      id
      slipCode
      date
    }
  }
`;

/**
 * __useGetLatestIdOfSlipQuery__
 *
 * To run a query within a React component, call `useGetLatestIdOfSlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestIdOfSlipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestIdOfSlipQuery({
 *   variables: {
 *      FindLatestSlip: // value for 'FindLatestSlip'
 *   },
 * });
 */
export function useGetLatestIdOfSlipQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestIdOfSlipQuery,
    GetLatestIdOfSlipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestIdOfSlipQuery,
    GetLatestIdOfSlipQueryVariables
  >(GetLatestIdOfSlipDocument, options);
}
export function useGetLatestIdOfSlipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestIdOfSlipQuery,
    GetLatestIdOfSlipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestIdOfSlipQuery,
    GetLatestIdOfSlipQueryVariables
  >(GetLatestIdOfSlipDocument, options);
}
export type GetLatestIdOfSlipQueryHookResult = ReturnType<
  typeof useGetLatestIdOfSlipQuery
>;
export type GetLatestIdOfSlipLazyQueryHookResult = ReturnType<
  typeof useGetLatestIdOfSlipLazyQuery
>;
export type GetLatestIdOfSlipQueryResult = Apollo.QueryResult<
  GetLatestIdOfSlipQuery,
  GetLatestIdOfSlipQueryVariables
>;
export const GetLatestVoucherDocument = gql`
  query getLatestVoucher {
    getLatestVoucher {
      id
      voucherNumber
      date
    }
  }
`;

/**
 * __useGetLatestVoucherQuery__
 *
 * To run a query within a React component, call `useGetLatestVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVoucherQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestVoucherQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestVoucherQuery,
    GetLatestVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestVoucherQuery, GetLatestVoucherQueryVariables>(
    GetLatestVoucherDocument,
    options,
  );
}
export function useGetLatestVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestVoucherQuery,
    GetLatestVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestVoucherQuery,
    GetLatestVoucherQueryVariables
  >(GetLatestVoucherDocument, options);
}
export type GetLatestVoucherQueryHookResult = ReturnType<
  typeof useGetLatestVoucherQuery
>;
export type GetLatestVoucherLazyQueryHookResult = ReturnType<
  typeof useGetLatestVoucherLazyQuery
>;
export type GetLatestVoucherQueryResult = Apollo.QueryResult<
  GetLatestVoucherQuery,
  GetLatestVoucherQueryVariables
>;
export const GetLatestVoucherCodeDocument = gql`
  query getLatestVoucherCode {
    getLatestVoucherCode {
      id
      title
      code
      type
      isScheduler
      amount
      outStandingAmount
      schedulerOf
      userId
    }
  }
`;

/**
 * __useGetLatestVoucherCodeQuery__
 *
 * To run a query within a React component, call `useGetLatestVoucherCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestVoucherCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestVoucherCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestVoucherCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestVoucherCodeQuery,
    GetLatestVoucherCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestVoucherCodeQuery,
    GetLatestVoucherCodeQueryVariables
  >(GetLatestVoucherCodeDocument, options);
}
export function useGetLatestVoucherCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestVoucherCodeQuery,
    GetLatestVoucherCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestVoucherCodeQuery,
    GetLatestVoucherCodeQueryVariables
  >(GetLatestVoucherCodeDocument, options);
}
export type GetLatestVoucherCodeQueryHookResult = ReturnType<
  typeof useGetLatestVoucherCodeQuery
>;
export type GetLatestVoucherCodeLazyQueryHookResult = ReturnType<
  typeof useGetLatestVoucherCodeLazyQuery
>;
export type GetLatestVoucherCodeQueryResult = Apollo.QueryResult<
  GetLatestVoucherCodeQuery,
  GetLatestVoucherCodeQueryVariables
>;
export const GetLedgerOfOnePartyDocument = gql`
  query getLedgerOfOneParty($id: Float!) {
    getLedgerOfOneParty(id: $id) {
      partyId
      id
      preBalance
      postBalance
      particularId
      particularType
      description
      sale {
        id
        type
        description
        totalPrice
        addLess
        anyPayment
        subTotal
        address
        returnSale
        city
        saleOnCash
        miscellaneous
        date
        invoiceNumber
      }
      receiptSlip {
        id
        onAccountOf
        partyId
        date
        slipType
        cashChequeBank
        signature
        slipCode
        amount
        sumOfRupees
      }
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetLedgerOfOnePartyQuery__
 *
 * To run a query within a React component, call `useGetLedgerOfOnePartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerOfOnePartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerOfOnePartyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLedgerOfOnePartyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLedgerOfOnePartyQuery,
    GetLedgerOfOnePartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLedgerOfOnePartyQuery,
    GetLedgerOfOnePartyQueryVariables
  >(GetLedgerOfOnePartyDocument, options);
}
export function useGetLedgerOfOnePartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLedgerOfOnePartyQuery,
    GetLedgerOfOnePartyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLedgerOfOnePartyQuery,
    GetLedgerOfOnePartyQueryVariables
  >(GetLedgerOfOnePartyDocument, options);
}
export type GetLedgerOfOnePartyQueryHookResult = ReturnType<
  typeof useGetLedgerOfOnePartyQuery
>;
export type GetLedgerOfOnePartyLazyQueryHookResult = ReturnType<
  typeof useGetLedgerOfOnePartyLazyQuery
>;
export type GetLedgerOfOnePartyQueryResult = Apollo.QueryResult<
  GetLedgerOfOnePartyQuery,
  GetLedgerOfOnePartyQueryVariables
>;
export const GetMonthlyExpenseReportDocument = gql`
  query getMonthlyExpenseReport {
    getMonthlyExpenseReport {
      reportDuration
      expenseType
      userId
      amount
      id
    }
  }
`;

/**
 * __useGetMonthlyExpenseReportQuery__
 *
 * To run a query within a React component, call `useGetMonthlyExpenseReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyExpenseReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyExpenseReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonthlyExpenseReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonthlyExpenseReportQuery,
    GetMonthlyExpenseReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMonthlyExpenseReportQuery,
    GetMonthlyExpenseReportQueryVariables
  >(GetMonthlyExpenseReportDocument, options);
}
export function useGetMonthlyExpenseReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonthlyExpenseReportQuery,
    GetMonthlyExpenseReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonthlyExpenseReportQuery,
    GetMonthlyExpenseReportQueryVariables
  >(GetMonthlyExpenseReportDocument, options);
}
export type GetMonthlyExpenseReportQueryHookResult = ReturnType<
  typeof useGetMonthlyExpenseReportQuery
>;
export type GetMonthlyExpenseReportLazyQueryHookResult = ReturnType<
  typeof useGetMonthlyExpenseReportLazyQuery
>;
export type GetMonthlyExpenseReportQueryResult = Apollo.QueryResult<
  GetMonthlyExpenseReportQuery,
  GetMonthlyExpenseReportQueryVariables
>;
export const GetMonthlyReportDocument = gql`
  query getMonthlyReport {
    getMonthlyReport {
      totalSales
      totalPurcahses
      totalPayments
      totalReceipts
      totalMatersSold
      reportDuration
      userId
    }
  }
`;

/**
 * __useGetMonthlyReportQuery__
 *
 * To run a query within a React component, call `useGetMonthlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonthlyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonthlyReportQuery,
    GetMonthlyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>(
    GetMonthlyReportDocument,
    options,
  );
}
export function useGetMonthlyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonthlyReportQuery,
    GetMonthlyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonthlyReportQuery,
    GetMonthlyReportQueryVariables
  >(GetMonthlyReportDocument, options);
}
export type GetMonthlyReportQueryHookResult = ReturnType<
  typeof useGetMonthlyReportQuery
>;
export type GetMonthlyReportLazyQueryHookResult = ReturnType<
  typeof useGetMonthlyReportLazyQuery
>;
export type GetMonthlyReportQueryResult = Apollo.QueryResult<
  GetMonthlyReportQuery,
  GetMonthlyReportQueryVariables
>;
export const FilterPartiesDocument = gql`
  query filterParties($findMatchPartyInput: FindPartyInput!) {
    filterParties(findMatchPartyInput: $findMatchPartyInput) {
      items {
        id
        partyCode
        partyName
        company
        phoneNumber
        mobileNumber
        address
        email
        openingBalance
        city
        balance
        debitBalance
        creditBalance
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
      totalBalance
    }
  }
`;

/**
 * __useFilterPartiesQuery__
 *
 * To run a query within a React component, call `useFilterPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterPartiesQuery({
 *   variables: {
 *      findMatchPartyInput: // value for 'findMatchPartyInput'
 *   },
 * });
 */
export function useFilterPartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterPartiesQuery,
    FilterPartiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPartiesQuery, FilterPartiesQueryVariables>(
    FilterPartiesDocument,
    options,
  );
}
export function useFilterPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterPartiesQuery,
    FilterPartiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPartiesQuery, FilterPartiesQueryVariables>(
    FilterPartiesDocument,
    options,
  );
}
export type FilterPartiesQueryHookResult = ReturnType<
  typeof useFilterPartiesQuery
>;
export type FilterPartiesLazyQueryHookResult = ReturnType<
  typeof useFilterPartiesLazyQuery
>;
export type FilterPartiesQueryResult = Apollo.QueryResult<
  FilterPartiesQuery,
  FilterPartiesQueryVariables
>;
export const GetPartyByIdDocument = gql`
  query getPartyById($id: Int!) {
    getPartyById(id: $id) {
      id
      partyCode
      partyName
      company
      phoneNumber
      mobileNumber
      address
      openingBalance
      email
      city
      balance
      creditBalance
      debitBalance
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetPartyByIdQuery__
 *
 * To run a query within a React component, call `useGetPartyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyByIdQuery,
    GetPartyByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(
    GetPartyByIdDocument,
    options,
  );
}
export function useGetPartyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyByIdQuery,
    GetPartyByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(
    GetPartyByIdDocument,
    options,
  );
}
export type GetPartyByIdQueryHookResult = ReturnType<
  typeof useGetPartyByIdQuery
>;
export type GetPartyByIdLazyQueryHookResult = ReturnType<
  typeof useGetPartyByIdLazyQuery
>;
export type GetPartyByIdQueryResult = Apollo.QueryResult<
  GetPartyByIdQuery,
  GetPartyByIdQueryVariables
>;
export const GetPartyWithIdDocument = gql`
  query getPartyWithId($id: String!) {
    getPartyWithId(id: $id) {
      id
      partyCode
      partyName
      company
      phoneNumber
      mobileNumber
      address
      openingBalance
      email
      city
      balance
      creditBalance
      debitBalance
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetPartyWithIdQuery__
 *
 * To run a query within a React component, call `useGetPartyWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyWithIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartyWithIdQuery,
    GetPartyWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartyWithIdQuery, GetPartyWithIdQueryVariables>(
    GetPartyWithIdDocument,
    options,
  );
}
export function useGetPartyWithIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartyWithIdQuery,
    GetPartyWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartyWithIdQuery, GetPartyWithIdQueryVariables>(
    GetPartyWithIdDocument,
    options,
  );
}
export type GetPartyWithIdQueryHookResult = ReturnType<
  typeof useGetPartyWithIdQuery
>;
export type GetPartyWithIdLazyQueryHookResult = ReturnType<
  typeof useGetPartyWithIdLazyQuery
>;
export type GetPartyWithIdQueryResult = Apollo.QueryResult<
  GetPartyWithIdQuery,
  GetPartyWithIdQueryVariables
>;
export const BalanceSheetDocument = gql`
  query balanceSheet($findMatchPartyInput: FindPartyInput!) {
    balanceSheet(findBalance: $findMatchPartyInput) {
      items {
        id
        partyCode
        partyName
        company
        phoneNumber
        mobileNumber
        address
        email
        city
        balance
        debitBalance
        creditBalance
        openingBalance
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
      totalBalance
    }
  }
`;

/**
 * __useBalanceSheetQuery__
 *
 * To run a query within a React component, call `useBalanceSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceSheetQuery({
 *   variables: {
 *      findMatchPartyInput: // value for 'findMatchPartyInput'
 *   },
 * });
 */
export function useBalanceSheetQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceSheetQuery,
    BalanceSheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BalanceSheetQuery, BalanceSheetQueryVariables>(
    BalanceSheetDocument,
    options,
  );
}
export function useBalanceSheetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceSheetQuery,
    BalanceSheetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BalanceSheetQuery, BalanceSheetQueryVariables>(
    BalanceSheetDocument,
    options,
  );
}
export type BalanceSheetQueryHookResult = ReturnType<
  typeof useBalanceSheetQuery
>;
export type BalanceSheetLazyQueryHookResult = ReturnType<
  typeof useBalanceSheetLazyQuery
>;
export type BalanceSheetQueryResult = Apollo.QueryResult<
  BalanceSheetQuery,
  BalanceSheetQueryVariables
>;
export const GetProductWithIdDocument = gql`
  query getProductWithId($id: String!) {
    getProductWithId(id: $id) {
      id
      itemCode
      quality
      name
      unitPurchasePrice
      amount
      pcsOrBundle
      meterOrSuit
      sumUpPcsOrBundleBalance
      sumUpMeterOrSuitBalance
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetProductWithIdQuery__
 *
 * To run a query within a React component, call `useGetProductWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductWithIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductWithIdQuery,
    GetProductWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductWithIdQuery, GetProductWithIdQueryVariables>(
    GetProductWithIdDocument,
    options,
  );
}
export function useGetProductWithIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductWithIdQuery,
    GetProductWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductWithIdQuery,
    GetProductWithIdQueryVariables
  >(GetProductWithIdDocument, options);
}
export type GetProductWithIdQueryHookResult = ReturnType<
  typeof useGetProductWithIdQuery
>;
export type GetProductWithIdLazyQueryHookResult = ReturnType<
  typeof useGetProductWithIdLazyQuery
>;
export type GetProductWithIdQueryResult = Apollo.QueryResult<
  GetProductWithIdQuery,
  GetProductWithIdQueryVariables
>;
export const GetStockListDocument = gql`
  query getStockList($itemCode: String!) {
    getStockList(itemCode: $itemCode) {
      id
      itemCode
      quality
      name
      unitPurchasePrice
      amount
      pcsOrBundle
      sumUpMeterOrSuitBalance
      sumUpPcsOrBundleBalance
      meterOrSuit
      sale {
        id
        type
        totalPrice
        addLess
        anyPayment
        subTotal
        address
        city
        saleOnCash
        miscellaneous
        returnSale
        date
        invoiceNumber
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetStockListQuery__
 *
 * To run a query within a React component, call `useGetStockListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockListQuery({
 *   variables: {
 *      itemCode: // value for 'itemCode'
 *   },
 * });
 */
export function useGetStockListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockListQuery,
    GetStockListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockListQuery, GetStockListQueryVariables>(
    GetStockListDocument,
    options,
  );
}
export function useGetStockListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockListQuery,
    GetStockListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockListQuery, GetStockListQueryVariables>(
    GetStockListDocument,
    options,
  );
}
export type GetStockListQueryHookResult = ReturnType<
  typeof useGetStockListQuery
>;
export type GetStockListLazyQueryHookResult = ReturnType<
  typeof useGetStockListLazyQuery
>;
export type GetStockListQueryResult = Apollo.QueryResult<
  GetStockListQuery,
  GetStockListQueryVariables
>;
export const FilterProductsDocument = gql`
  query filterProducts($findMatchProductInput: FindProductInput!) {
    filterProducts(findMatchProductInput: $findMatchProductInput) {
      items {
        id
        itemCode
        quality
        name
        unitPurchasePrice
        amount
        sumUpMeterOrSuitBalance
        sumUpPcsOrBundleBalance
        pcsOrBundle
        meterOrSuit
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
      amount
    }
  }
`;

/**
 * __useFilterProductsQuery__
 *
 * To run a query within a React component, call `useFilterProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterProductsQuery({
 *   variables: {
 *      findMatchProductInput: // value for 'findMatchProductInput'
 *   },
 * });
 */
export function useFilterProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterProductsQuery,
    FilterProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterProductsQuery, FilterProductsQueryVariables>(
    FilterProductsDocument,
    options,
  );
}
export function useFilterProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterProductsQuery,
    FilterProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterProductsQuery, FilterProductsQueryVariables>(
    FilterProductsDocument,
    options,
  );
}
export type FilterProductsQueryHookResult = ReturnType<
  typeof useFilterProductsQuery
>;
export type FilterProductsLazyQueryHookResult = ReturnType<
  typeof useFilterProductsLazyQuery
>;
export type FilterProductsQueryResult = Apollo.QueryResult<
  FilterProductsQuery,
  FilterProductsQueryVariables
>;
export const FilterSaleDocument = gql`
  query filterSale($findMatchSaleInput: FindSaleInput!) {
    filterSale(findMatchSaleInput: $findMatchSaleInput) {
      items {
        id
        party {
          id
          partyCode
          partyName
          company
          phoneNumber
          city
          mobileNumber
          address
          openingBalance
          email
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
        products {
          id
          itemCode
          quality
          name
          unitPurchasePrice
          pcsOrBundle
          meterOrSuit
          amount
          challanItem {
            total
            pcsOrBundle
            meterOrSuits
          }
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
        totalPrice
        subTotal
        returnSale
        address
        type
        addLess
        invoiceNumber
        date
        miscellaneous
        anyPayment
        city
        saleOnCash
      }
      totalSalePurchase
      total
    }
  }
`;

/**
 * __useFilterSaleQuery__
 *
 * To run a query within a React component, call `useFilterSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterSaleQuery({
 *   variables: {
 *      findMatchSaleInput: // value for 'findMatchSaleInput'
 *   },
 * });
 */
export function useFilterSaleQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterSaleQuery,
    FilterSaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterSaleQuery, FilterSaleQueryVariables>(
    FilterSaleDocument,
    options,
  );
}
export function useFilterSaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterSaleQuery,
    FilterSaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterSaleQuery, FilterSaleQueryVariables>(
    FilterSaleDocument,
    options,
  );
}
export type FilterSaleQueryHookResult = ReturnType<typeof useFilterSaleQuery>;
export type FilterSaleLazyQueryHookResult = ReturnType<
  typeof useFilterSaleLazyQuery
>;
export type FilterSaleQueryResult = Apollo.QueryResult<
  FilterSaleQuery,
  FilterSaleQueryVariables
>;
export const FilterSlipDocument = gql`
  query filterSlip($findReceiptSlip: FindReceiptSlip!) {
    filterSlip(findReceiptSlip: $findReceiptSlip) {
      items {
        id
        onAccountOf
        partyId
        partyDetail {
          id
          partyCode
          partyName
          company
          phoneNumber
          city
          mobileNumber
          address
          balance
          creditBalance
          debitBalance
          email
        }
        date
        slipType
        cashChequeBank
        signature
        slipCode
        amount
        sumOfRupees
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
      totalReciepts
    }
  }
`;

/**
 * __useFilterSlipQuery__
 *
 * To run a query within a React component, call `useFilterSlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterSlipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterSlipQuery({
 *   variables: {
 *      findReceiptSlip: // value for 'findReceiptSlip'
 *   },
 * });
 */
export function useFilterSlipQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterSlipQuery,
    FilterSlipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterSlipQuery, FilterSlipQueryVariables>(
    FilterSlipDocument,
    options,
  );
}
export function useFilterSlipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterSlipQuery,
    FilterSlipQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterSlipQuery, FilterSlipQueryVariables>(
    FilterSlipDocument,
    options,
  );
}
export type FilterSlipQueryHookResult = ReturnType<typeof useFilterSlipQuery>;
export type FilterSlipLazyQueryHookResult = ReturnType<
  typeof useFilterSlipLazyQuery
>;
export type FilterSlipQueryResult = Apollo.QueryResult<
  FilterSlipQuery,
  FilterSlipQueryVariables
>;
export const FindSlipBySlipIdDocument = gql`
  query findSlipBySlipId($findSlipById: FindSlipById!) {
    findSlipBySlipId(findSlipById: $findSlipById) {
      id
      onAccountOf
      partyId
      partyDetail {
        id
        partyCode
        partyName
        company
        phoneNumber
        city
        mobileNumber
        address
        balance
        creditBalance
        debitBalance
        email
      }
      date
      slipType
      cashChequeBank
      signature
      slipCode
      amount
      sumOfRupees
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useFindSlipBySlipIdQuery__
 *
 * To run a query within a React component, call `useFindSlipBySlipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSlipBySlipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSlipBySlipIdQuery({
 *   variables: {
 *      findSlipById: // value for 'findSlipById'
 *   },
 * });
 */
export function useFindSlipBySlipIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSlipBySlipIdQuery,
    FindSlipBySlipIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindSlipBySlipIdQuery, FindSlipBySlipIdQueryVariables>(
    FindSlipBySlipIdDocument,
    options,
  );
}
export function useFindSlipBySlipIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSlipBySlipIdQuery,
    FindSlipBySlipIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSlipBySlipIdQuery,
    FindSlipBySlipIdQueryVariables
  >(FindSlipBySlipIdDocument, options);
}
export type FindSlipBySlipIdQueryHookResult = ReturnType<
  typeof useFindSlipBySlipIdQuery
>;
export type FindSlipBySlipIdLazyQueryHookResult = ReturnType<
  typeof useFindSlipBySlipIdLazyQuery
>;
export type FindSlipBySlipIdQueryResult = Apollo.QueryResult<
  FindSlipBySlipIdQuery,
  FindSlipBySlipIdQueryVariables
>;
export const GetTotalOfAllDocument = gql`
  query getTotalOfAll {
    getTotalOfAll {
      crTotal
      drTotal
      purchaseTotal
      saleTotal
      totalMatersSold
      cashInBank
      cashInHand
      totalStockAmount
    }
  }
`;

/**
 * __useGetTotalOfAllQuery__
 *
 * To run a query within a React component, call `useGetTotalOfAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalOfAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalOfAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalOfAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTotalOfAllQuery,
    GetTotalOfAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTotalOfAllQuery, GetTotalOfAllQueryVariables>(
    GetTotalOfAllDocument,
    options,
  );
}
export function useGetTotalOfAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalOfAllQuery,
    GetTotalOfAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTotalOfAllQuery, GetTotalOfAllQueryVariables>(
    GetTotalOfAllDocument,
    options,
  );
}
export type GetTotalOfAllQueryHookResult = ReturnType<
  typeof useGetTotalOfAllQuery
>;
export type GetTotalOfAllLazyQueryHookResult = ReturnType<
  typeof useGetTotalOfAllLazyQuery
>;
export type GetTotalOfAllQueryResult = Apollo.QueryResult<
  GetTotalOfAllQuery,
  GetTotalOfAllQueryVariables
>;
export const FilterVoucherDocument = gql`
  query filterVoucher($getVoucherInput: getVoucherInput!) {
    filterVoucher(getVoucherInput: $getVoucherInput) {
      items {
        id
        voucherItem {
          id
          voucherCodeDetail {
            id
            title
            code
            userId
          }
          description
          recd
          paid
        }
        totalPaid
        remarks
        totalReceived
        signature
        voucherNumber
        date
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
    }
  }
`;

/**
 * __useFilterVoucherQuery__
 *
 * To run a query within a React component, call `useFilterVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterVoucherQuery({
 *   variables: {
 *      getVoucherInput: // value for 'getVoucherInput'
 *   },
 * });
 */
export function useFilterVoucherQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterVoucherQuery,
    FilterVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterVoucherQuery, FilterVoucherQueryVariables>(
    FilterVoucherDocument,
    options,
  );
}
export function useFilterVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterVoucherQuery,
    FilterVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterVoucherQuery, FilterVoucherQueryVariables>(
    FilterVoucherDocument,
    options,
  );
}
export type FilterVoucherQueryHookResult = ReturnType<
  typeof useFilterVoucherQuery
>;
export type FilterVoucherLazyQueryHookResult = ReturnType<
  typeof useFilterVoucherLazyQuery
>;
export type FilterVoucherQueryResult = Apollo.QueryResult<
  FilterVoucherQuery,
  FilterVoucherQueryVariables
>;
export const GetVoucherCodesDocument = gql`
  query getVoucherCodes($isScheduler: Boolean, $type: String, $title: String) {
    getVoucherCodes(isScheduler: $isScheduler, type: $type, title: $title) {
      id
      title
      code
      type
      isScheduler
      amount
      outStandingAmount
      schedulerOf
      userId
      voucherItem {
        recd
        paid
        voucher {
          voucherNumber
          date
        }
      }
    }
  }
`;

/**
 * __useGetVoucherCodesQuery__
 *
 * To run a query within a React component, call `useGetVoucherCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoucherCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoucherCodesQuery({
 *   variables: {
 *      isScheduler: // value for 'isScheduler'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetVoucherCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVoucherCodesQuery,
    GetVoucherCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVoucherCodesQuery, GetVoucherCodesQueryVariables>(
    GetVoucherCodesDocument,
    options,
  );
}
export function useGetVoucherCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVoucherCodesQuery,
    GetVoucherCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVoucherCodesQuery,
    GetVoucherCodesQueryVariables
  >(GetVoucherCodesDocument, options);
}
export type GetVoucherCodesQueryHookResult = ReturnType<
  typeof useGetVoucherCodesQuery
>;
export type GetVoucherCodesLazyQueryHookResult = ReturnType<
  typeof useGetVoucherCodesLazyQuery
>;
export type GetVoucherCodesQueryResult = Apollo.QueryResult<
  GetVoucherCodesQuery,
  GetVoucherCodesQueryVariables
>;
export const GetMatchInvoiceDocument = gql`
  query getMatchInvoice($invoiceNumber: String!, $type: saleTypeEnums!) {
    getMatchInvoice(invoiceNumber: $invoiceNumber, type: $type) {
      id
      party {
        id
        partyCode
        sales {
          id
          party {
            id
            partyCode
            partyName
            company
            phoneNumber
            city
            mobileNumber
            address
            balance
            debitBalance
            openingBalance
            creditBalance
            email
          }
          type
          products {
            id
            itemCode
            quality
            name
            unitPurchasePrice
            pcsOrBundle
            meterOrSuit
            amount
            sumUpMeterOrSuitBalance
            sumUpPcsOrBundleBalance
          }
          totalPrice
          addLess
          anyPayment
          subTotal
          returnSale
          address
          city
          saleOnCash
          miscellaneous
          date
          invoiceNumber
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
        partyName
        company
        phoneNumber
        city
        mobileNumber
        address
        balance
        debitBalance
        openingBalance
        creditBalance
        email
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      type
      products {
        id
        itemCode
        quality
        name
        unitPurchasePrice
        pcsOrBundle
        meterOrSuit
        amount
        sale {
          id
          party {
            id
            partyCode
            partyName
            company
            phoneNumber
            city
            mobileNumber
            address
            balance
            debitBalance
            openingBalance
            creditBalance
            email
          }
          type
          products {
            id
            itemCode
            quality
            name
            unitPurchasePrice
            pcsOrBundle
            meterOrSuit
            amount
            sumUpMeterOrSuitBalance
            sumUpPcsOrBundleBalance
          }
          totalPrice
          addLess
          anyPayment
          subTotal
          returnSale
          address
          city
          saleOnCash
          miscellaneous
          date
          invoiceNumber
          timestamps {
            createdAt
            updatedAt
            deletedAt
          }
        }
        sumUpMeterOrSuitBalance
        sumUpPcsOrBundleBalance
        challanItem {
          total
          meterOrSuits
          pcsOrBundle
        }
        timestamps {
          createdAt
          updatedAt
          deletedAt
        }
      }
      totalPrice
      addLess
      anyPayment
      subTotal
      returnSale
      address
      city
      saleOnCash
      miscellaneous
      date
      invoiceNumber
      timestamps {
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetMatchInvoiceQuery__
 *
 * To run a query within a React component, call `useGetMatchInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchInvoiceQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetMatchInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMatchInvoiceQuery,
    GetMatchInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMatchInvoiceQuery, GetMatchInvoiceQueryVariables>(
    GetMatchInvoiceDocument,
    options,
  );
}
export function useGetMatchInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMatchInvoiceQuery,
    GetMatchInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMatchInvoiceQuery,
    GetMatchInvoiceQueryVariables
  >(GetMatchInvoiceDocument, options);
}
export type GetMatchInvoiceQueryHookResult = ReturnType<
  typeof useGetMatchInvoiceQuery
>;
export type GetMatchInvoiceLazyQueryHookResult = ReturnType<
  typeof useGetMatchInvoiceLazyQuery
>;
export type GetMatchInvoiceQueryResult = Apollo.QueryResult<
  GetMatchInvoiceQuery,
  GetMatchInvoiceQueryVariables
>;
export const ProfitLossGetterDocument = gql`
  query profitLossGetter {
    profitLossGetter {
      partner {
        name
        total
      }
      payable
      stockInShop
      receivable
      cashInHand
      balanceInBank
      partnersWithdrawal {
        name
        total
      }
      totalExpenses
    }
  }
`;

/**
 * __useProfitLossGetterQuery__
 *
 * To run a query within a React component, call `useProfitLossGetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitLossGetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitLossGetterQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfitLossGetterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfitLossGetterQuery,
    ProfitLossGetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfitLossGetterQuery, ProfitLossGetterQueryVariables>(
    ProfitLossGetterDocument,
    options,
  );
}
export function useProfitLossGetterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfitLossGetterQuery,
    ProfitLossGetterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfitLossGetterQuery,
    ProfitLossGetterQueryVariables
  >(ProfitLossGetterDocument, options);
}
export type ProfitLossGetterQueryHookResult = ReturnType<
  typeof useProfitLossGetterQuery
>;
export type ProfitLossGetterLazyQueryHookResult = ReturnType<
  typeof useProfitLossGetterLazyQuery
>;
export type ProfitLossGetterQueryResult = Apollo.QueryResult<
  ProfitLossGetterQuery,
  ProfitLossGetterQueryVariables
>;
